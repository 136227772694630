import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useConfirm from './useConfirm';

function useConfirmExit(confirmExit, when = true) {
    const { navigator } = useContext(NavigationContext);
    useEffect(() => {
        if (!when) {
            return;
        }
        const push = navigator.push;
        navigator.push = async(...args) => {
            const result = await confirmExit();
            if (result !== false) {
                push(...args);
            }
        };
        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}
export function usePrompt(message, when = true) {
    const { isConfirmed } = useConfirm();
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
           
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);
    const confirmExit = useCallback(() => {
        const confirm = isConfirmed(message);
        return confirm;
    }, [message]);
    useConfirmExit(confirmExit, when);
}
import React, { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import Auth from '../../auth/Auth';
import {Button,Collapse} from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;
export default function Table(props) {
    const dataCheck = props.data !== undefined && props.data !== null;
    const [formTable, setFormTable] = useState('');
    const [formTableLabel, setFormTableLabel] = useState('');
    const [formColumns, setFormColumns] = useState([]);
    const [formFilter, setFormFilter] = useState({label:'',value:''});
    const [formCondition, setFormCondition] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [filterColumn, setFilterColumn] = useState('');
    const [dataSource, setDataSource] = useState({label:'',value:''});
    const [showTable, setShowTable] = useState(true);


    const [tableValue,setTableValue] = useState(props.table);
    const [columnsValue, setColumnsValue] = useState([]);

    const fullFilter = props.optionFilter.map((item, index) => {
        return {
            value: item.key,
            label: item.name,
            data_type: item.data_type
        }
    });

    const listColumns = (value) => {
        fetch(API_URL+'report/listColumns', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ table: value })
        }).then((response) => response.json())
            .then((data) => {
                if (data.response===1) {
                    const tmp = [];

                    data.result.map((item, index) => {
                        tmp.push({
                            value: item.name,
                            label: item.name,
                            type: item.data_type
                        });

                        return true;
                    });

                    setColumnsValue(tmp);
                }
            });
    }

    useEffect(() => {
        let hasDataSource = false;
        if (Object.entries(props.dataSource).toString()===Object.entries({label:'',value:''}).toString()) {
            setShowTable(true);
            hasDataSource = false;
        }else{
            setShowTable(false);
            hasDataSource = true;
        }
        
        if (dataCheck) {
            if (!hasDataSource) {
                setFormTable(props.data.table);
                setFormTableLabel(props.data.tableLabel);
                if (props.data.table) {
                    listColumns(props.data.table);
                }
            }else{
                setDataSource(props.dataSource);
                setFormTable(props.dataSource.value);
                setFormTableLabel(props.dataSource.label);
                if (props.dataSource.value) {
                    listColumns(props.dataSource.value);
                }
            }
            
            setFormColumns(props.data.columns);
            
            if (props.data.filter != null && typeof props.data.filter != 'undefined') {
                let open = true;
                let obj = {
                    column:'',
                    filter:{label:'',value:''},
                    condition:''
                }
                if (Object.entries(props.data.filter).toString()===Object.entries(obj).toString()) {
                    open = false;
                }
                setFilterColumn(props.data.filter.column);
                setFormFilter(props.data.filter.filter);
                setFormCondition(props.data.filter.condition);
                setOpenFilter(open);
            }
        }
        if (!props.table || !props.table.length) {
            props.refreshTable();
        }else{
            setTableValue(props.table);
        }
    }, [props]);

    useEffect(()=>{
        let type ='';
        if (filterColumn.type === 'text') {
            type='string';
        }else if (filterColumn.type === 'integer') {
            type='number';
        }
        let tmp = [];
        fullFilter.forEach(item=>{
            if (item.data_type.includes(type)) {
                let obj = {
                    value:item.value,
                    label:item.label
                }
                tmp.push(obj);
            }
        });
        setFilterList(tmp);
    },[filterColumn]);
    

    return (
        <>
            {showTable?<div className="form-group mb-3">
                <label htmlFor="" className="form-label">Data Source</label>
                <Select value={{ label: formTableLabel, value: formTable }} defaultValue={{ label: formTableLabel, value: formTable }} options={tableValue} className="w-100" isMulti={false} onChange={(e) => {
                    setFormTable(e.value);
                    setFormTableLabel(e.label);
                    listColumns(e.value);
                    var obj = {
                        table: e.value,
                        tableLabel:e.label,
                        columns: formColumns,
                        filter:{
                            column:filterColumn,
                            filter:formFilter,
                            condition:formCondition
                        }
                    };

                    props.setValue(obj);
                }} />
            </div>:''}
            <div className="form-group mb-3">
                <label htmlFor="" className="form-label">Columns</label>
                <Select value={formColumns} options={columnsValue} className="w-100" isMulti={true} onChange={(e) => {
                    let arr = [];
                    e.forEach((obj) => {
                        arr.push({
                            value: obj.value,
                            label: obj.value
                        })
                    });

                    setFormColumns(arr);
                    var obj = {
                        table: formTable,
                        tableLabel:formTableLabel,
                        columns: arr,
                        filter:{
                            column:filterColumn,
                            filter:formFilter,
                            condition:formCondition
                        }
                    };

                    props.setValue(obj);
                }}/>
            </div>
            <div
                onClick={() => {
                    setOpenFilter(!openFilter)
                }}
                aria-controls="example-collapse-text"
                aria-expanded={openFilter}
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: 'pointer' }}>
                <div>
                    <Button
                        onClick={() => {
                            setOpenFilter(!openFilter)
                            
                        }}
                        className="bg-transparent p-0 text-secondary border-0 me-2"
                        aria-controls="example-collapse-text"
                        aria-expanded={openFilter}>
                        {!openFilter && <i className="fa fa-chevron-down"></i>}
                        {openFilter && <i className="fa fa-chevron-up"></i>}
                    </Button>
                    <label htmlFor="" className="form-label">Filter</label>
                </div>
                <div className="form-check form-switch">
                    <input
                        onChange={(e) => {
                            if (e.target.checked) {
                                setOpenFilter(true);
                            } else {
                                var obj = {
                                    table: formTable,
                                    tableLabel:formTableLabel,
                                    columns: formColumns,
                                    filter:{
                                        column:'',
                                        filter:{label:'',value:''},
                                        condition:'-'
                                    }
                                };

                                props.setValue(obj);
                                
                            }
                        }}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        aria-checked={openFilter}
                        id="flexSwitchCheckDefault" 
                        checked={openFilter} />
                </div>
            </div>
            <div className="mb-3">
                <Collapse in={openFilter}>
                    <div id="example-collapse-text">
                        <label htmlFor="" className="form-label">Filter Column</label>
                        <Select value={filterColumn} options={columnsValue} className="w-100" isMulti={false} onChange={(e) => {
                            setFilterColumn(e);
                            var obj = {
                                table: formTable,
                                tableLabel:formTableLabel,
                                columns: formColumns,
                                filter:{
                                    column:e,
                                    filter:formFilter,
                                    condition:formCondition
                                }
                            };

                            props.setValue(obj);
                        }}/>
                        <Select options={filterList} defaultValue={{ label: formFilter.label, value: formFilter.value }} value={{ label: formFilter.label, value: formFilter.value }} onChange={(e) => {
                            setFormFilter(e);
                            var obj = {
                                table: formTable,
                                tableLabel:formTableLabel,
                                columns: formColumns,
                                filter:{
                                    column:filterColumn,
                                    filter:e,
                                    condition:formCondition
                                }
                            };

                            props.setValue(obj);
                        }}></Select>
                        <input type="text" onChange={(e) => { 
                            setFormCondition(e.target.value);
                            var obj = {
                                table: formTable,
                                tableLabel:formTableLabel,
                                columns: formColumns,
                                filter:{
                                    column:filterColumn,
                                    filter:formFilter,
                                    condition:e.target.value
                                }
                            };
                            props.setValue(obj);
                        }} className="form-control" placeholder="Condition" value={formCondition} />
                    </div>
                </Collapse>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import Auth from '../auth/Auth';
import Loader from './Loader';

const API_URL = process.env.REACT_APP_API_URL;
const Widgets = (props) => {
    const [widgetOne, setWidgetOne] = useState(null);
    const [widgetTwo, setWidgetTwo] = useState(null);
    const [widgetThree, setWidgetThree] = useState(null);
    const [widgetFour, setWidgetFour] = useState(null);

    const getData = async (item, key) => {
        if (item.table !== undefined && item.data !== undefined) {
            let body = {
                table: item.table,
                column: item.field,
                operation: item.action,
                data:item.data,
                component: 'widget',
                filter:item.filter,
                global_filter:props.globalFilter
            };

            return await fetch(API_URL+'report/data-query', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'token': Auth.token
                },
                body: JSON.stringify({ data: body })
            }).then(response => response.json())
                .then(data => {
                    if (data.response === 1) {
                        switch (key) {
                            case 0:
                                setWidgetOne({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: data.result.data,
                                    icon: item.icon
                                })
                                break;

                            case 1:
                                setWidgetTwo({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: data.result.data,
                                    icon: item.icon
                                })
                                break;

                            case 2:
                                setWidgetThree({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: data.result.data,
                                    icon: item.icon
                                })
                                break;

                            case 3:
                                setWidgetFour({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: data.result.data,
                                    icon: item.icon
                                })
                                break;

                            default:
                                break;
                        }
                    } else {
                        switch (key) {
                            case 0:
                                setWidgetOne({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: 0,
                                    icon: item.icon
                                })

                                break;

                            case 1:
                                setWidgetTwo({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: 0,
                                    icon: item.icon
                                })

                                break;

                            case 2:
                                setWidgetThree({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: 0,
                                    icon: item.icon
                                })

                                break;

                            case 3:
                                setWidgetFour({
                                    background: item.background,
                                    color: item.text,
                                    name: item.name,
                                    value: 0,
                                    icon: item.icon
                                })

                                break;

                            default:
                                break;
                        }
                    }
                });
        } else {
            switch (key) {
                case 0:
                    setWidgetOne({
                        background: item.background,
                        color: item.text,
                        name: item.name,
                        value: 0,
                        icon: item.icon
                    })

                    break;

                case 1:
                    setWidgetTwo({
                        background: item.background,
                        color: item.text,
                        name: item.name,
                        value: 0,
                        icon: item.icon
                    })

                    break;

                case 2:
                    setWidgetThree({
                        background: item.background,
                        color: item.text,
                        name: item.name,
                        value: 0,
                        icon: item.icon
                    })

                    break;

                case 3:
                    setWidgetFour({
                        background: item.background,
                        color: item.text,
                        name: item.name,
                        value: 0,
                        icon: item.icon
                    })

                    break;

                default:
                    break;
            }
        }

    }

    useEffect(() => {
        if (props.data !== undefined && props.data !== null) {
            if (
                props.data.widgetOne!==undefined && props.data.widgetOne!==null &&
                props.data.widgetTwo!==undefined && props.data.widgetTwo!==null &&
                props.data.widgetThree!==undefined && props.data.widgetThree!==null &&
                props.data.widgetFour!==undefined && props.data.widgetFour!==null
                ) {
                setWidgetOne(props.data.widgetOne);
                setWidgetTwo(props.data.widgetTwo);
                setWidgetThree(props.data.widgetThree);
                setWidgetFour(props.data.widgetFour);
            }else{
                props.data.map((item, index) => {
                    getData(item, index);
                    return true;
                });
            }
            
        }

    }, [props]);

    return (
        <>
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3">
                    {widgetOne ? (<div className="card widgets">
                        <div className="card-body d-flex justify-content-start align-items-center">
                            <div className="i-widget-container me-3">
                                <span style={{ backgroundColor: widgetOne.background, color: widgetOne.color }}>
                                    {/* <FontAwesomeIcon icon={widgetOne.icon} /> */}
                                    <i style={{ color: widgetOne.color }} className={widgetOne.icon}></i>
                                </span>
                            </div>
                            <div className="s-widget-container">
                                <p>{widgetOne.name}</p>
                                <span>{widgetOne.value}</span>
                            </div>
                        </div>
                    </div>)
                        : (<Loader />)
                    }
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3">
                    {widgetTwo ? (<div className="card widgets">
                        <div className="card-body d-flex justify-content-start align-items-center">
                            <div className="i-widget-container me-3">
                                <span style={{ backgroundColor: widgetTwo.background, color: widgetTwo.color }}>
                                    <i style={{ color: widgetTwo.color }} className={widgetTwo.icon}></i>
                                </span>
                            </div>
                            <div className="s-widget-container">
                                <p>{widgetTwo.name}</p>
                                <span>{widgetTwo.value}</span>
                            </div>
                        </div>
                    </div>)
                        : (<Loader />)
                    }
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3">
                    {widgetThree ? (<div className="card widgets">
                        <div className="card-body d-flex justify-content-start align-items-center">
                            <div className="i-widget-container me-3">
                                <span style={{ backgroundColor: widgetThree.background, color: widgetThree.color }}>
                                    <i style={{ color: widgetThree.color }} className={widgetThree.icon}></i>
                                </span>
                            </div>
                            <div className="s-widget-container">
                                <p>{widgetThree.name}</p>
                                <span>{widgetThree.value}</span>
                            </div>
                        </div>
                    </div>)
                        : (<Loader />)
                    }
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-6 mb-3">
                    {widgetFour ? (<div className="card widgets">
                        <div className="card-body d-flex justify-content-start align-items-center">
                            <div className="i-widget-container me-3">
                                <span style={{ backgroundColor: widgetFour.background, color: widgetFour.color }}>
                                    <i style={{ color: widgetFour.color }} className={widgetFour.icon}></i>
                                </span>
                            </div>
                            <div className="s-widget-container">
                                <p>{widgetFour.name}</p>
                                <span>{widgetFour.value}</span>
                            </div>
                        </div>
                    </div>)
                        : (<Loader />)
                    }
                </div>
            </div >
        </>
    )
}

export default Widgets;
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { PieChart, Pie, Cell, Legend } from 'recharts';
import Loader from './Loader';
import Auth from '../auth/Auth';

const API_URL = process.env.REACT_APP_API_URL;
const ChartPie = (props) => {
    const color = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    const targetRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const [title, setTitle] = useState(null);
    const [xAspectRatio] = useState(1);
    const [yAspectRatio, setYAspectRatio] = useState(0.65);
    const [chartData, setChartData] = useState([]);

    const getChartData = async () => {
        let body = {
            table: props.data.table,
            field: props.data.field,
            operation: props.data.action,
            component: 'chart',
            type:'pie',
            filter:props.data.filter,
            global_filter:props.globalFilter
        };

        await fetch(API_URL + 'report/data-query', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ data: body })
        }).then(response => response.json())
            .then(data => {
                if (data.response === 1) {
                    let result = data.result.map(item=>{
                        return {
                            name:item.name,
                            value:parseFloat(item.value)
                        }
                    })
                    setChartData(result);
                }
            });
    };

    useEffect(() => {
        if (props.data !== undefined && props.data !== null) {
            if (props.data.data !== undefined && props.data.data !== null && Array.isArray(props.data.data)) {
                setChartData(props.data.data);
            } else if (props.data.field !== undefined && props.data.field !== null) {

                getChartData();

                if (props.data.title !== undefined && props.data.title !== null && props.data.title !== '') {
                    setTitle(props.data.title);
                }

                if (props.data.aspectRatio !== undefined && props.data.aspectRatio !== null) {
                    if (props.data.aspectRatio.x !== undefined && props.data.aspectRatio.x !== null) {
                        if (props.data.aspectRatio.y !== undefined && props.data.aspectRatio !== null) {
                            setYAspectRatio(
                                (parseFloat(props.data.aspectRatio.y) / parseFloat(props.data.aspectRatio.x))
                            );
                        }
                    }
                }
            }

            if (props.type !== undefined && props.type !== null) {
                if (props.type.title !== undefined && props.type.title !== null && props.type.title !== '') {
                    setTitle(props.type.title);
                }

                if (props.type.aspectRatio !== undefined && props.type.aspectRatio !== null) {
                    if (props.type.aspectRatio.x !== undefined && props.type.aspectRatio.x !== null) {
                        if (props.type.aspectRatio.y !== undefined && props.type.aspectRatio !== null) {
                            setYAspectRatio(
                                (parseFloat(props.type.aspectRatio.y) / parseFloat(props.type.aspectRatio.x))
                            );
                        }
                    }
                }
            }
        }
    }, [props]);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: Math.floor(xAspectRatio * targetRef.current.offsetWidth),
                height: Math.floor(yAspectRatio * targetRef.current.offsetWidth)
            });
        }
    }, []);

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: Math.floor(xAspectRatio * targetRef.current.offsetWidth),
                height: Math.floor(yAspectRatio * targetRef.current.offsetWidth)
            });
        }
    }, [yAspectRatio, title]);


    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: 0.65 * targetRef.current.offsetWidth
            });
        }
    }, []);

    return (
        <div className="mb-5" style={{ maxWidth: 'inherit' }} ref={targetRef}>
            {title !== null && <h6 className='text-center'>{title}</h6>}
            {chartData !== null && Object.keys(chartData).length > 0 ?
                (
                    <PieChart width={dimensions.width} height={dimensions.height}>
                        <Pie
                            data={chartData}
                            cx={dimensions.width/2}
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="line"
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={color[index % color.length]} />
                            ))}
                        </Pie>
                        <Legend wrapperStyle={{
                            paddingTop:"30px"
                        }}/>
                    </PieChart>
                )
                : <Loader />
            }
        </div>
    )
}

export default ChartPie;

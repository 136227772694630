import React from 'react'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './index.css';

import AuthMiddleware from './middleware/AuthMiddleware';
import Layout from "./components/layouts/Layouts"

import Dashboard from "./pages/Dashboard"
import Login from "./pages/Login"
import List from "./pages/List"
import Template from "./pages/Template"
import NotFound from "./pages/errors/NotFound";
import { Helmet } from 'react-helmet';
import ConfirmContextProvider from './components/utils/ConfirmContextProvider';
import DefaultTemplates from './pages/DefaultTemplates';

const App = () => {
    return (
        <ConfirmContextProvider>
            <Helmet>
                <title>ColibriView</title>
            </Helmet>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/login/:userId" element={<Login />} />
                    <Route element={<AuthMiddleware />}>
                        <Route element={<Layout />}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/list" element={<List />} />
                            <Route path="/template/" element={<Template />} />
                            <Route path="/template/:idDefault" element={<Template />} />
                            <Route path="/template/:idTemplate/:preview" element={<Template />} />
                            <Route path="/template/default/:idTemplate/" element={<DefaultTemplates />} />
                        </Route>
                    </Route>
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </ConfirmContextProvider>

    )
}

export default App;
import React, { Suspense } from 'react'
import Loader from '../../Loader'
import Widgets from '../../Widgets'

function DefaultWidget() {
    const data = {
        widgetOne: {
            background: '#000000',
            color: '#FFFFFF',
            name: 'Widget One',
            value: '100',
            icon: 'fa fa-home'
        },
        widgetTwo: {
            background: '#000000',
            color: '#FFFFFF',
            name: 'Widget Two',
            value: '100',
            icon: 'fa fa-home'
        },
        widgetThree: {
            background: '#000000',
            color: '#FFFFFF',
            name: 'Widget Three',
            value: '100',
            icon: 'fa fa-home'
        },
        widgetFour: {
            background: '#000000',
            color: '#FFFFFF',
            name: 'Widget Four',
            value: '100',
            icon: 'fa fa-home'
        },
    }
    return (
        <div className="section-container-filled mb-4">
            <Suspense fallback={<Loader />}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>
                        Four Widgets
                        <span className="badge ms-3" style={{
                            color: '#FCA549',
                            fontWeight: 500,
                            backgroundColor: 'rgba(252, 165, 73, 0.08)'
                        }}>4 Widget</span>
                    </h5>
                </div>
                <Widgets data={data} />
            </Suspense>
        </div>
    )
}

export default DefaultWidget
import React from 'react'

import Chart from './Chart';
import Table from './Table';
import Widgets from './Widgets';
import Chart2 from './Chart2';

export default function Forms(props) {
    const operatorList = [
        { key: 'COUNT', name: 'COUNT', is_numeric: false },
        { key: 'COUNT_DISTINCT', name: 'COUNT DISTINCT', is_numeric: false },
        { key: 'SUM', name: 'SUM', is_numeric: true },
        { key: 'MIN', name: 'MIN', is_numeric: true },
        { key: 'MAX', name: 'MAX', is_numeric: true },
        { key: 'AVG', name: 'AVERAGE', is_numeric: true },
        { key: 'NONE', name: 'NONE', is_numeric: true },
    ];

    switch (props.type) {
        case 'table':
            return <Table id={props.id} data={props.data} table={props.table} setValue={props.setValue} optionFilter={props.filterList} refreshTable={props.refreshTable}  dataSource={props.dataSource} globalFilter={props.globalFilter}/>

        case '4 widget':
            return <Widgets id={props.id} data={props.data} table={props.table} setValue={props.setValue} action={operatorList} optionFilter={props.filterList} refreshTable={props.refreshTable} dataSource={props.dataSource} globalFilter={props.globalFilter}/>

        case 'chart':
            return <Chart id={props.id} data={props.data} table={props.table} setValue={props.setValue} action={operatorList} optionFilter={props.filterList} refreshTable={props.refreshTable} dataSource={props.dataSource} globalFilter={props.globalFilter}/>

        case 'chart2':
            return <Chart2 id={props.id} data={props.data} table={props.table} setValue={props.setValue} action={operatorList} optionFilter={props.filterList} refreshTable={props.refreshTable} setWarning={props.setWarning} dataSource={props.dataSource} globalFilter={props.globalFilter}/>

        default:
            return <></>
    }
}

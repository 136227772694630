import React, { lazy, Suspense, useEffect, useState } from 'react';
import Loader from '../Loader';

const WidgetForm = lazy(() => import('./widget/WidgetForm'));

const Widgets = (props) => {
    const [firstForm, setfirstForm] = useState(null);
    const [secondForm, setsecondForm] = useState(null);
    const [thirdForm, setthirdForm] = useState(null);
    const [fourthForm, setfourthForm] = useState(null);

    const setFormValue = (value) => {
        switch (value.id) {
            case 1:
                setfirstForm(value);
                break;
            case 2:
                setsecondForm(value);
                break;
            case 3:
                setthirdForm(value);
                break;
            case 4:
                setfourthForm(value);
                break;

            default:
                break;
        }
    }

    const handleSave = () => {
        props.setValue({
            value: [
                Object.assign({ id: 1 }, firstForm),
                Object.assign({ id: 2 }, secondForm),
                Object.assign({ id: 3 }, thirdForm),
                Object.assign({ id: 4 }, fourthForm)
            ]
        });
    }

    useEffect(() => {
        if (props.data !== undefined && props.data !== null) {
            if (props.data.value !== undefined && props.data.value !== null) {
                props.data.value.map((item, index) => {
                    switch (index) {
                        case 0:
                            setfirstForm(item);
                            break;
                        case 1:
                            setsecondForm(item);
                            break;
                        case 2:
                            setthirdForm(item);
                            break;
                        case 3:
                            setfourthForm(item);
                            break;

                        default:
                            break;
                    }

                    return true;
                });
            }
        }
    }, [props]);

    useEffect(() => {
    }, [firstForm, secondForm, thirdForm, fourthForm]);


    return (
        <>
            <div className="text-center mb-3">
                <button
                    type="button" className="customize w-100 modalClicker" data-bs-target={"#exampleModal" + props.id}>
                    Customize
                </button>
            </div>
            <div className="modal fade" id={"exampleModal" + props.id} tabIndex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-xl modal-fullscreen-md-down shadow border-0">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row px-2 py-3">
                                <div className="col-lg-3 col-md-6 px-3">
                                    <Suspense fallback={<Loader />}>
                                        <WidgetForm id={1} setValue={setFormValue} table={props.table} data={firstForm} action={props.action} optionFilter={props.optionFilter} dataSource={props.dataSource}/>
                                    </Suspense>
                                </div>

                                <div className="col-lg-3 col-md-6 px-3">
                                    <Suspense fallback={<Loader />}>
                                        <WidgetForm id={2} setValue={setFormValue} table={props.table} data={secondForm} action={props.action} optionFilter={props.optionFilter} dataSource={props.dataSource}/>
                                    </Suspense>
                                </div>

                                <div className="col-lg-3 col-md-6 px-3">
                                    <Suspense fallback={<Loader />}>
                                        <WidgetForm id={3} setValue={setFormValue} table={props.table} data={thirdForm} action={props.action} optionFilter={props.optionFilter} dataSource={props.dataSource}/>
                                    </Suspense>
                                </div>

                                <div className="col-lg-3 col-md-6 px-3">
                                    <Suspense fallback={<Loader />}>
                                        <WidgetForm id={4} setValue={setFormValue} table={props.table} data={fourthForm} action={props.action} optionFilter={props.optionFilter} dataSource={props.dataSource}/>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button onClick={() => handleSave()} type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Widgets;

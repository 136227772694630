import React, { useEffect, useState } from 'react';

import Auth, { GetSessionStatus } from '../auth/Auth';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import '../styles/login.css';

const COLIBRI_URL = process.env.REACT_APP_COLIBRI_URL;
const API_URL = process.env.REACT_APP_API_URL;
const Login = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const {userId} = useParams();
    const [loading, setLoading] = useState(true)
    const [loginAttempt, setLoginAttempt] = useState(false);
    const [msg, setMsg] = useState({
        status: false,
        label: '',
        msg: ''
    });

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberPassword, setRememberPassword] = useState(false);

    const toast = new bootstrap.Toast(document.getElementById('toast'));

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginAttempt(true);

        const attempt = () => Auth.attempt(username, password, rememberPassword).then((attempt) => {
            if (attempt.status) {
                navigate('/dashboard', { replace: true, from: location });
            } else {
                setMsg({
                    status: attempt.status,
                    label: 'danger',
                    msg: attempt.msg
                });

                toast.show();
            }

            setLoginAttempt(false);
        });

        attempt();
    }

    useEffect(() => {
        setLoading(true)
        GetSessionStatus()
            .then(response => {
                if (response.status) {
                    navigate('/dashboard', { replace: true, from: location });
                }else if (typeof userId !== 'undefined') {
                    fetch(API_URL+'autoLogin', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userId: userId })
                    }).then(response => response.json())
                        .then(data => {
                            console.log('Data :',data);
                            var encrypted = Auth.encode64(JSON.stringify({
                                status: true,
                                token: data.token,
                                user: {
                                    userId: data.userId,
                                    nama:data.nama
                                }
                            }));
        
                            localStorage.setItem('session', encrypted);
                            
                            return encrypted
                        }).then(session=>{
                            session = JSON.parse(Auth.decode64(session));
                            if (session.status) {
                                Auth.token = session.token;
                                Auth.user = session.user;
                                Auth.nama = session.nama;
                                Auth.status = session.status;
                                navigate('/dashboard', { replace: true, from: location });
                            }else{
                                navigate('/', { replace: true, from: location });
                                // window.location.href = COLIBRI_URL;
                            }
                        }).finally(() => setLoading(false))
                }else{
                    if (Auth.status) {
                        navigate('/dashboard', { replace: true, from: location });
                    }else{
                        navigate('/', { replace: true, from: location });
                        // window.location.href = COLIBRI_URL;
                    }

                    setLoading(false)
                }
            })

        
    }, [])

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                Sedang memuat....
            </div>
        )
    }

    return (
        <section id="login">
            <div className="toast-container position-fixed top-0 end-0 p-3">
                <div id="toast" className="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">{msg.msg}</div>
                        <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center align-items-center container" style={{ minHeight: 100 + "vh" }}>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="d-flex flex-wrap justify-content-center mb-4">
                            <img src="/img/logo.png" alt="Logo" />
                        </div>
                        <h1 className="card-title text-center">Sign In to Our Platform</h1>

                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group mb-3">
                                <label htmlFor="email" className="form-label">Your Email</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa fa-envelope"></i>
                                    </span>
                                    <input type="email" id="email" value={username} onChange={(e) => { setUsername(e.target.value) }} className="form-control" />
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="password" className="form-label">Your Password</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                    <input type="password" id="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-4">
                                <div className="form-check">
                                    <input
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setRememberPassword(true);
                                            } else {
                                                setRememberPassword(false);
                                            }
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Remember Me
                                    </label>
                                </div>
                                {/* <a href="/" className="forgot-pwd">Forgot Password?</a> */}
                            </div>

                            <button type="submit" className="btn btn-primary w-100" disabled={(loginAttempt) ? true : false}>
                                {loginAttempt ? <div className="spinner-border spinner-border-sm text-light" role="status"><span className="visually-hidden">Loading...</span></div>
                                    : 'Sign In'}
                            </button>
                        </form>
                    </div>
                </div>
            </div >
        </section>
    )
}

export default Login;
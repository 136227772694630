import React from 'react'
import Navbar from './navbar/Navbar'
import { Outlet } from 'react-router-dom';

import "../../styles/dashboard.css";

const Layout = () => {
    return (
        <React.Fragment>
            <Navbar />
            <Outlet />
        </React.Fragment>
    )
}

export default Layout;
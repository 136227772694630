import React, { useEffect, useState, useRef} from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import Auth from '../auth/Auth';

const API_URL = process.env.REACT_APP_API_URL;
export default function Gauge(props) {
    const [chartData, setChartData] = useState(0);
    const [title, setTitle] = useState('');

    const targetRef = useRef(null);

    const getChartData = async () => {
        let body = {
            table: props.data.table,
            field: props.data.field,
            value: props.data.value,
            component: 'chart',
            type:'gauge',
            global_filter:props.globalFilter
        };

        await fetch(API_URL+'report/data-query', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ data: body })
        }).then(response => response.json())
            .then(data => {
                if (data.response === 1) {
                    let percent = data.result/100;
                    setChartData(percent.toFixed(3));
                }
            });
    };

    useEffect(() => {
        if (typeof props.data !== 'undefined' && props.data != null) {
            if (props.data.value!==undefined && props.data.value!==null) {
                setChartData(props.data.value);
            }
            let tableCheck = typeof props.data.table !== 'undefined' && props.data.table != null;
            let fieldCheck = typeof props.data.field !== 'undefined' && props.data.field != null;
            let valueCheck = typeof props.data.value !== 'undefined' && props.data.value != null;
            if (tableCheck && fieldCheck && valueCheck) {
                getChartData();
            }

            if (props.data.title !== undefined && props.data.title !== null && props.data.title !== '') {
                setTitle(props.data.title);
            }
        }

        if (props.type !== undefined && props.type !== null) {
            if (props.type.title !== undefined && props.type.title !== null && props.type.title !== '') {
                setTitle(props.type.title);
            }
        }
    }, [props]);
    

    return (
        <div ref={targetRef} >
            <h6 className='text-center'>{title}</h6>
            <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'100%'
                }}>
                <ReactSpeedometer
                    maxSegmentLabels={0}
                    maxValue={100}
                    value={(chartData*100)}
                    currentValueText="${value}"
                    needleColor="#201e34"
                    startColor="green"
                    segments={20}
                    endColor="red"
                    textColor="#201e34"
                    
                />
            </div>
        </div>
        
    );

}
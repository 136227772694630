import React, { Suspense, useEffect, useState } from 'react'
import Chart from '../../Chart';
import ChartLine from '../../ChartLine';
import ChartPie from '../../ChartPie';
import Gauge from '../../Gauge';
import Loader from '../../Loader'
import StackedChart from '../../StackedChart';

function Default2Charts(props) {
    const [data, setData] = useState([3,4])
    const pie={
        data:[
            {name:'Data A',value:73},
            {name:'Data B',value:27}
        ]
    }

    const gauge={value:0.83};

    const stacked={
        y:[{label:'pv'},{label:'uv'}],
        x:'name',
        data:[
            {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
            },
        ]
    };

    const area={
        y:[{label:'amt'}],
        x:'name',
        data: [
            {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
            },
        ]
    };

    useEffect(() => {
      setData(props.data);
    }, [props])
    

    return (
        <div className="section-container-filled mb-4">
            <Suspense fallback={<Loader />}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>
                        Two Charts
                        <span className="badge ms-3" style={{
                            color: '#07A287',
                            fontWeight: 500,
                            backgroundColor: 'rgba(7, 162, 135, 0.08)'
                        }}>Chart</span>
                    </h5>
                </div>
                <div className="row">
                {data.map((item, index) => {
                    return (
                        <div className="col-xxl-6 col-xl-6 col-12" key={index}>
                            {
                                (item !== null) &&
                                <>
                                    {(item === 1) && <StackedChart data={stacked} />}
                                    {(item === 2) && <Chart data={area} />}
                                    {(item === 3) && <ChartPie data={pie} />}
                                    {(item === 4) && <Gauge data={gauge} />}
                                    {(item === 5) && <ChartLine data={area} />}
                                </>
                            }
                        </div>
                    )
                })}
                </div>
                
            </Suspense>
        </div>
    )
}

export default Default2Charts
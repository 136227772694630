import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, Label } from 'recharts';
import Loader from './Loader';
import Auth from '../auth/Auth';

const API_URL = process.env.REACT_APP_API_URL;
const Chart = (props) => {
    const color = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];

    const targetRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const [x, setX] = useState([]);
    const [y, setY] = useState([]);
    const [title, setTitle] = useState(null);
    const [xAspectRatio] = useState(1);
    const [yAspectRatio, setYAspectRatio] = useState(0.65);
    const [chartData, setChartData] = useState([]);

    const getChartData = async () => {
        let body = {
            table: props.data.table,
            xcolumn: props.data.x,
            ycolumn: props.data.y,
            yoperation: props.data.action,
            component: 'chart',
            type:'area',
            filter:props.data.filter,
            global_filter:props.globalFilter
        };

        await fetch(API_URL+'report/data-query', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ data: body })
        }).then(response => response.json())
            .then(data => {
                if (data.response === 1) {
                    let result = data.result.map(item=>{
                        return {
                            [props.data.x]:item[props.data.x],
                            [props.data.y[0].value]:parseFloat(item[props.data.y[0].value])
                        }
                    })
                    setChartData(result);
                }
            });
    };

    useEffect(() => {
        if (props.data !== undefined && props.data !== null) {
            if (props.data.data !== undefined && props.data.data !== null) {
                setChartData(props.data.data);
            }
            if (props.data.x !== undefined && props.data.x !== null && props.data.y !== undefined && props.data.y !== null) {
                setX(props.data.x);
                setY(props.data.y);

                getChartData();

                if (props.data.title !== undefined && props.data.title !== null && props.data.title !== '') {
                    setTitle(props.data.title);
                }

                if (props.data.aspectRatio !== undefined && props.data.aspectRatio !== null) {
                    if (props.data.aspectRatio.x !== undefined && props.data.aspectRatio.x !== null) {
                        if (props.data.aspectRatio.y !== undefined && props.data.aspectRatio !== null) {
                            setYAspectRatio(
                                (parseFloat(props.data.aspectRatio.y) / parseFloat(props.data.aspectRatio.x))
                            );
                        }
                    }
                }
            }

            if (props.type !== undefined && props.type !== null) {
                if (props.type.title !== undefined && props.type.title !== null && props.type.title !== '') {
                    setTitle(props.type.title);
                }

                if (props.type.aspectRatio !== undefined && props.type.aspectRatio !== null) {
                    if (props.type.aspectRatio.x !== undefined && props.type.aspectRatio.x !== null) {
                        if (props.type.aspectRatio.y !== undefined && props.type.aspectRatio !== null) {
                            setYAspectRatio(
                                (parseFloat(props.type.aspectRatio.y) / parseFloat(props.type.aspectRatio.x))
                            );
                        }
                    }
                }
            }
        }
    }, [props]);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: Math.floor(xAspectRatio * targetRef.current.offsetWidth),
                height: 0.5 * Math.floor(yAspectRatio * targetRef.current.offsetWidth)
            });
        }
    }, []);

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: Math.floor(xAspectRatio * targetRef.current.offsetWidth),
                height: 0.5 * Math.floor(yAspectRatio * targetRef.current.offsetWidth)
            });
        }
    }, [yAspectRatio, title]);


    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: 0.65 * targetRef.current.offsetWidth
            });
        }
    }, []);

    return (
        <div className="mb-5" style={{ maxWidth: 'inherit' }} ref={targetRef}>
            {chartData !== null && Object.keys(chartData).length > 0 ?
                (
                    <AreaChart
                        width={dimensions.width}
                        height={dimensions.height}
                        data={chartData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <XAxis
                            orientation="bottom"
                            minTickGap={0}
                            tickMargin={10}
                            dataKey={x}
                            tickSize={3}
                            interval={0}
                            tickFormatter={(string, index) => {
                                const limit = 10;
                                if (string.length >= limit) {
                                    let val = string.substring(0, limit) + '...';
                                    return val;
                                } return string;
                            }}
                            tick={{ fontSize: 8 + 'px' }}
                            padding={{ top: 15 }} >
                            {title !== null && <Label value={title} offset={0} position="insideBottom"></Label>}
                        </XAxis>
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {
                            y.map((item, index) => (<Area key={index} type="monotone" dataKey={item.label} stroke={color[index % 5]} fill={color[index % 5]} />))
                        }
                    </AreaChart>
                )
                : <Loader />
            }
        </div>
    )
}

export default Chart;

import React, { lazy, Suspense, useEffect, useState } from 'react';
import Loader from '../Loader';

const Chart2Form = lazy(() => import('./chart2/Chart2Form'));

const Chart2 = (props) => {
    const dataCheck = props.data !== undefined && props.data !== null;

    const [chartOne, setChartOne] = useState(null);
    const [chartTwo, setChartTwo] = useState(null);
    const [renderStates, setRenderStates] = useState(false);

    const setVal = (val) => {
        switch (val.id) {
            case 1:
                setChartOne(val);
                break;

            case 2:
                setChartTwo(val);
                break;

            default:
                break;
        }
    }

    const saveData = () => {
        props.setValue({
            form: [
                chartOne,
                chartTwo
            ]
        });
    }

    useEffect(() => {
        if (dataCheck) {
            if (props.data.form !== undefined && props.data.form !== null) {
                setChartOne(props.data.form[0]);
                setChartTwo(props.data.form[1]);
            }
        }
    }, [props]);

    useEffect(() => {
    }, [renderStates]);

    return (
        <>
            <div className="text-center mb-3">
                <button
                    onClick={() => {
                        setTimeout(() => {
                            setRenderStates(!renderStates)
                        }, 1500);
                    }}

                    type="button" className="customize w-100 modalClicker" data-bs-target={"#exampleModal" + props.id}>
                    Customize
                </button>
            </div>
            <div className="modal fade" id={"exampleModal" + props.id} tabIndex="-1" data-backdrop="false" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-fullscreen-md-down shadow border-0">
                    <div className="modal-content">
                        <div className="modal-body">
                            <ul className="nav nav-tabs mb-3" id={"myTab" + props.id} role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        onClick={() => {
                                            setTimeout(() => {
                                                setRenderStates(!renderStates)
                                            }, 1500);
                                        }}
                                        className="nav-link active"
                                        id={"home-tab" + props.id}
                                        data-bs-toggle="tab"
                                        data-bs-target={"#home-tab-pane" + props.id}
                                        type="button" role="tab" aria-controls={"home-tab-pane" + props.id} aria-selected="true">
                                        Chart 1
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        onClick={() => {
                                            setTimeout(() => {
                                                setRenderStates(!renderStates)
                                            }, 1500);
                                        }}
                                        className="nav-link"
                                        id={"profile-tab" + props.id} data-bs-toggle="tab" data-bs-target={"#profile-tab-pane" + props.id} type="button" role="tab" aria-controls={"profile-tab-pane" + props.id} aria-selected="false">
                                        Chart 2
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id={"myTabContent" + props.id}>
                                <div className="tab-pane fade show active" id={"home-tab-pane" + props.id} role="tabpanel" aria-labelledby={"home-tab" + props.id} tabIndex="0">
                                    <Suspense fallback={<Loader />}>
                                        <Chart2Form table={props.table} action={props.action} setValue={setVal} id={1} data={chartOne} optionFilter={props.optionFilter} setWarning={props.setWarning} dataSource={props.dataSource} globalFilter={props.globalFilter}/>
                                    </Suspense>
                                </div>
                                <div className="tab-pane fade" id={"profile-tab-pane" + props.id} role="tabpanel" aria-labelledby={"profile-tab" + props.id} tabIndex="0">
                                    <Suspense fallback={<Loader />}>
                                        <Chart2Form table={props.table} action={props.action} setValue={setVal} id={2} data={chartTwo} optionFilter={props.optionFilter} setWarning={props.setWarning} dataSource={props.dataSource} globalFilter={props.globalFilter}/>
                                    </Suspense>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button onClick={() => saveData()} type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chart2;
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ minHeight: 90 + 'vh' }}>
            <div className="col-lg-6 col-md-8 col-12 text-center">
                <div className="d-flex justify-content-center align-items-center mb-4">
                    <img src="/img/logo.png" className="img-fluid" alt="" />
                </div>
                <div className="mb-5">
                    <h1
                        className="mb-3"
                        style={{
                            fontSize: 3 + 'em',
                            fontWeight: 700
                        }}>
                        404 <br />
                        Not Found
                    </h1>
                    <small className="text-muted">The page you requested is Not Found</small>
                </div>

                <button onClick={() => navigate('/dashboard', { replace: true, from: location })} className="btn btn-primary rounded-pill">
                    <i className="fa fa-arrow-left me-2"></i> Back to Home
                </button>
            </div>
        </div>
    )
}

import '../styles/templates.css';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { Button, Modal, Badge } from 'react-bootstrap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Auth from '../auth/Auth';
import Pdf from "react-to-pdf";

import Accordion from 'react-bootstrap/Accordion';
import Section from '../components/section/component/Section';
import FormSection from '../components/section/form/FormSection';

import { usePrompt } from '../components/utils/prompt';
import ConfirmModal from '../components/utils/ConfirmModal';
import Select from 'react-select';
import Loader from '../components/Loader';


const API_URL = process.env.REACT_APP_API_URL;
const Templates = () => {
    const { idTemplate, preview, idDefault } = useParams();

    const navigate = useNavigate();

    const templateNameRef = useRef(null);
    const pdfContainer = useRef(null);

    const [printState, setPrintState] = useState(false);
    const [toastState, setToastState] = useState({
        status: false,
        msg: '',
        bg: 'success'
    });
    const [toastStateNoHide, setToastStateNoHide] = useState({
        status: false,
        msg: '',
        bg: 'success'
    });

    const [previewState, setPreviewState] = useState(false);
    const [previewPrintState, setPreviewPrintState] = useState(false);
    const [printDimension, setPrintDimension] = useState({ h: 1, w: 1 })
    const [readOnlyState, setReadOnlyState] = useState(true);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalDataSourceShow, setModalDataSourceShow] = useState(false);
    const [modalFilterGlobalShow, setModalFilterGlobalShow] = useState(false);

    const [globalTable, setGlobalTable] = useState({ label: '', value: '' });
    const [globalFilter, setGlobalFilter] = useState({
        column: '',
        filter: '',
        condition: ''
    });
    const [columnsValue, setColumnsValue] = useState([]);
    const [optFilter, setOptFilter] = useState([]);

    const [table, setTable] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [firstSection, setFirstSection] = useState(null);
    const [secondSection, setSecondSection] = useState(null);
    const [thirdSection, setThirdSection] = useState(null);
    const [fourthSection, setFourthSection] = useState(null);

    const [unsaved, setUnsaved] = useState(false)

    const [loading, setLoading] = useState(true)

    usePrompt('It looks like you have been editing something. If you leave before saving, your changes will be lost.', unsaved);

    const filterList = [
        {key:'>', name:'>', data_type:['number','datetime']},
        {key:'<', name:'<', data_type:['number','datetime']},
        {key:'>=', name:'>=', data_type:['number','datetime']},
        {key:'<=', name:'<=', data_type:['number','datetime']},
        {key:'=', name:'=', data_type:['number','string','datetime']},
        {key:'between', name:'between', data_type:['number','datetime']},
        {key:'in', name:'in', data_type:['string']},
        {key:'in like', name:'in like', data_type:['string','number','datetime']},
        {key:'not contain', name:'not contain', data_type:['string']},
        {key:'contain', name:'contain', data_type:['string']},
        {key:'begin with', name:'begin with', data_type:['string']},
        {key:'end with', name:'end with', data_type:['string']},
        {key:'is null', name:'is null', data_type:['string','number','datetime']},
    ];

    const undoDelete = (id, delData) => {
        switch (id) {
            case 1:
                setFirstSection(delData);
                break;

            case 2:
                setSecondSection(delData);
                break;

            case 3:
                setThirdSection(delData);
                break;

            case 4:
                setFourthSection(delData);
                break;
            default:
                break;
        }
    }

    const deleteComponent = (id) => {
        let deletedData = null;

        switch (id) {
            case 1:
                deletedData = firstSection;
                setFirstSection(null);
                break;

            case 2:
                deletedData = secondSection;
                setSecondSection(null);
                break;

            case 3:
                deletedData = thirdSection;
                setThirdSection(null);
                break;

            case 4:
                deletedData = fourthSection;
                setFourthSection(null);
                break;
            default:
                break;
        }

        setToastState({
            status: true,
            bg: 'warning',
            msg: (
                <div className="d-flex justify-content-between align-items-center">
                    <span>Deleting Data...</span>
                    <button onClick={() => undoDelete(id, deletedData)} className="btn btn-sm btn-light">UNDO</button>
                </div>
            )
        });
    }

    const SectionOne = () => {
        return <Section preview={previewState} deletePrompt={deleteComponent} id={1} data={firstSection} printState={printState} globalFilter={globalFilter}/>;
    };

    const SectionTwo = () => {
        return <Section preview={previewState} deletePrompt={deleteComponent} id={2} data={secondSection} printState={printState} globalFilter={globalFilter} />
    };

    const SectionThree = () => {
        return <Section preview={previewState} deletePrompt={deleteComponent} id={3} data={thirdSection} printState={printState} globalFilter={globalFilter} />;
    };

    const SectionFour = () => {
        return <Section preview={previewState} deletePrompt={deleteComponent} id={4} data={fourthSection} printState={printState} globalFilter={globalFilter} />;
    };

    const setFirstSectionValue = (value) => {
        setFirstSection(value);
    }

    const setSecondSectionValue = (value) => {
        setSecondSection(value);
    }

    const setThirdSectionValue = (value) => {
        setThirdSection(value);
    }

    const setFourthSectionValue = (value) => {
        setFourthSection(value);
    }

    const changeNameState = (e) => {
        if (readOnlyState) {
            setReadOnlyState(!readOnlyState);
            if (readOnlyState !== false) {
                templateNameRef.current.focus();
            }
        }
    }

    const loadData = async (id) => {
        await fetch(API_URL + 'template/load', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({
                dvTemplateId: id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    setTemplateName(data.result.dvTemplateName);
                    JSON.parse(data.result.dvTemplateValue).map((item, index) => {
                        switch (index) {
                            case 0:
                                setFirstSection(item);
                                break;

                            case 1:
                                setSecondSection(item);
                                break;

                            case 2:
                                setThirdSection(item);
                                break;

                            case 3:
                                setFourthSection(item);
                                break;

                            default:
                                break;
                        }

                        return true;
                    });

                    let settings = JSON.parse(data.result.dvTemplateGlobalSettings);
                    setGlobalTable(settings.data_source);
                    setGlobalFilter(settings.global_filter);
                    if (Object.entries(settings.data_source).toString()!==Object.entries({label:'',value:''}).toString()) {
                        handleCloseDataSource();
                    }
                }
            });
    }

    const loadDataDefault = async (id) => {
        await fetch(API_URL + 'template/default/load', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({
                dvTemplateId: id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    setTemplateName(data.result.dvTemplateName);
                    JSON.parse(data.result.dvTemplateValue).map((item, index) => {
                        switch (index) {
                            case 0:
                                setFirstSection(item);
                                break;

                            case 1:
                                setSecondSection(item);
                                break;

                            case 2:
                                setThirdSection(item);
                                break;

                            case 3:
                                setFourthSection(item);
                                break;

                            default:
                                break;
                        }

                        return true;
                    });
                }
            });
    }

    const saveData = async () => {
        setToastState({
            status: true,
            msg: 'Saving Template',
            bg: 'warning'
        });

        const body = {
            'dvTemplateId': (idTemplate !== undefined) ? idTemplate : null,
            'template_name': templateName,
            'content': [
                firstSection,
                secondSection,
                thirdSection,
                fourthSection
            ],
            'settings':{
                'data_source':globalTable,
                'global_filter':globalFilter
            }
        }
        await fetch(API_URL + 'template/save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    setToastState({
                        status: true,
                        msg: 'Berhasil Menyimpan Template',
                        bg: 'success'
                    })
                    setUnsaved(false);
                }
            });
    }

    const deleteData = async () => {
        await fetch(API_URL + 'template/delete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ dvTemplateId: idTemplate })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    setModalDeleteShow(false);
                    navigate('/dashboard');
                }
            });
    }

    useLayoutEffect(() => {
        if (pdfContainer.current) {
            setPrintDimension({
                h: pdfContainer.current.offsetHeight,
                w: pdfContainer.current.offsetWidth
            });
        }
    }, [pdfContainer]);

    useEffect(() => {
        setLoading(true)
        if (idTemplate !== undefined) {
            loadData(idTemplate);
        }else{
            if (Object.entries(globalTable).toString()===Object.entries({label:'',value:''}).toString()) {
                handleShowDataSource();
            }
        }

        if (idDefault !== undefined) {
            loadDataDefault(idDefault)
            // let json = [
            //     {
            //         "name": "Four Widgets",
            //         "type": "4 widget",
            //         "value": [
            //             {
            //                 "id": 1,
            //                 "name":"Widget One",
            //                 "text": "#FFFFFF",
            //                 "background": "#000000",
            //                 "icon": "fa fa-home"
            //             },
            //             {
            //                 "id": 2,
            //                 "name":"Widget Two",
            //                 "text": "#FFFFFF",
            //                 "background": "#000000",
            //                 "icon": "fa fa-home"
            //             },
            //             {
            //                 "id": 3,
            //                 "name":"Widget Three",
            //                 "text": "#FFFFFF",
            //                 "background": "#000000",
            //                 "icon": "fa fa-home"
            //             },
            //             {
            //                 "id": 4,
            //                 "name":"Widget Four",
            //                 "text": "#FFFFFF",
            //                 "background": "#000000",
            //                 "icon": "fa fa-home"
            //             }
            //         ],
            //         "section": 1
            //     },
            //     {
            //         "name": "Area chart",
            //         "type": "chart",
            //         "section": 2
            //     },
            //     {
            //         "form": [
            //             {
            //                 "id": 1,
            //                 "form": {
            //                     "chart": "pie"
            //                 }
            //             },
            //             {
            //                 "id": 2,
            //                 "form": {
            //                     "chart": "gauge"
            //                 }
            //             }
            //         ],
            //         "name": "Two Charts",
            //         "type": "chart2",
            //         "section": 3
            //     },
            //     {
            //         "name": "Table",
            //         "type": "table",
            //         "section": 4
            //     }
            // ];
            // json.map((item,index)=>{
            //     switch (index) {
            //         case 0:
            //             setFirstSection(item);
            //             break;

            //         case 1:
            //             setSecondSection(item);
            //             break;

            //         case 2:
            //             setThirdSection(item);
            //             break;

            //         case 3:
            //             setFourthSection(item);
            //             break;

            //         default:
            //             break;
            //     }

            //     return true;
            // })
        }

        // Table Fetching
        const tableData = async () => {
            await fetch(API_URL + 'report/listTables', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'token': Auth.token
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.response === 1) {
                        // const tb = [];
                        // data.result.map((item, index) => {
                        //     tb.push({
                        //         value: item,
                        //         label: item
                        //     });

                        //     return true;
                        // });

                        setTable(data.result);
                    }
                });
        }

        tableData().catch(console.error);

        if (preview !== undefined && preview === 'preview') {
            setPrintState(true);
            setPreviewState(true);
        }
        
        setLoading(false)
    }, []);

    // useEffect(() => {
    //     if(previewState){
            
    //     }
    // }, [previewState])
    

    useEffect(() => {
        if (globalTable.value!='') {
            listColumns(globalTable.value);
        }
    }, [globalTable])

    useEffect(()=>{
        if (globalFilter.column!='') {
            let type ='';
            if (globalFilter.column.type === 'text') {
                type='string';
            }else if (globalFilter.column.type === 'integer') {
                type='number';
            }
            let tmp = [];
            filterList.forEach(item=>{
                if (item.data_type.includes(type)) {
                    let obj = {
                        value:item.key,
                        label:item.name
                    }
                    tmp.push(obj);
                }
            });
            setOptFilter(tmp);
        }
        
    },[globalFilter]);
    
    // Table Fetching
    const getTableData = async () => {
        await fetch(API_URL + 'report/listTables', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    setTable(data.result);
                }
            });
    }

    const listColumns = (value) => {
        fetch(API_URL+'report/listColumns', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ table: value })
        }).then((response) => response.json())
            .then((data) => {
                if (data.response===1) {
                    const tmp = [];

                    data.result.map((item, index) => {
                        tmp.push({
                            value: item.name,
                            label: item.name,
                            type: item.data_type
                        });

                        return true;
                    });

                    setColumnsValue(tmp);
                }
            });
    }

    const handleCloseDataSource = () => setModalDataSourceShow(false);
    const handleShowDataSource = () => setModalDataSourceShow(true);
    const handleCloseFilterGlobal = () => setModalFilterGlobalShow(false);
    const handleShowFilterGlobal = () => setModalFilterGlobalShow(true);

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Loader/>
            </div>
        )
    }

    return (
        <div className="container-fluid my-4" id="templates">
            <div className="row">
                <div className={(previewState) ? "col-12" : "col-md-9"}>
                    <div className="card card-container" ref={pdfContainer}>
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-lg-4 mb-4">
                                    <div className="input-group template-name">
                                        <input type="text" className="form-control" value={templateName} onDoubleClick={() => changeNameState()} onChange={(e) => setTemplateName(e.target.value)} placeholder="Blank" aria-label="Blank" ref={templateNameRef}
                                            aria-describedby="basic-addon2" readOnly={readOnlyState} />
                                        <span className="input-group-text no-print" style={{ cursor: "pointer" }} onClick={() => {
                                            setReadOnlyState(!readOnlyState);
                                            if (readOnlyState !== false) {
                                                templateNameRef.current.focus();
                                            }
                                        }} id="basic-addon2" hidden={printState}>
                                            {(readOnlyState && printState === false) && <i className="text-primary fa fa-pencil"></i>}
                                            {(!readOnlyState && printState === false) && <i className="text-primary fa fa-save"></i>}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-8 mb-4 d-flex justify-content-lg-end justify-content-center">
                                    <div hidden={printState} className="no-print">
                                        <Pdf
                                            onComplete={() => {
                                                setPrintState(false);
                                            }}
                                            targetRef={pdfContainer}
                                            filename={templateName !== '' ? templateName : "template.pdf"}
                                            options={{
                                                orientation: 'potrait',
                                                unit: 'px',
                                                format: [printDimension.w, printDimension.h]
                                            }}
                                            scale={1}>
                                            {({ toPdf }) => (
                                                <button onClick={() => {
                                                    if (pdfContainer.current) {
                                                        setPrintDimension({
                                                            h: 0.55 * pdfContainer.current.offsetHeight,
                                                            w: 0.65 * pdfContainer.current.offsetWidth
                                                        });
                                                    }
                                                    setToastStateNoHide({
                                                        status: true,
                                                        bg: 'warning',
                                                        msg: 'Generating PDF'
                                                    });

                                                    setPrintState(true);
                                                    setTimeout(() => {
                                                        if (pdfContainer.current) {
                                                            setPrintDimension({
                                                                h: pdfContainer.current.offsetHeight,
                                                                w: pdfContainer.current.offsetWidth
                                                            });
                                                        }

                                                        toPdf();

                                                        setTimeout(() => {
                                                            setToastStateNoHide({
                                                                status: false,
                                                                bg: '',
                                                                msg: ''
                                                            });

                                                            setToastState({
                                                                status: true,
                                                                bg: 'success',
                                                                msg: 'Generate PDF Success'
                                                            });
                                                        }, 5000);
                                                    }, 15000);

                                                }} className="btn btn-sm btn-outline-danger me-2"><i className="fa fa-download"></i> PDF</button>
                                            )}
                                        </Pdf>

                                        <button hidden={idTemplate == null || idTemplate === undefined} onClick={() => setModalDeleteShow(true)} className="btn btn-sm btn-outline-secondary me-2"><i className="fa fa-trash"></i> Delete</button>
                                        <button onClick={() => saveData()} className="btn btn-sm btn-outline-primary me-2"><i className="fa fa-save"></i> Save</button>
                                        <button onClick={handleShowFilterGlobal} className="btn btn-sm btn-outline-primary me-2"><i className="fa-solid fa-filter"></i></button>
                                        <button onClick={handleShowDataSource} className="btn btn-sm btn-outline-success me-2"><i className="fa-solid fa-database"></i></button>
                                    </div>
                                    <div hidden={!previewState || previewPrintState}>
                                        <Pdf
                                            onComplete={() => {
                                                setPreviewPrintState(false);
                                            }}
                                            targetRef={pdfContainer}
                                            filename={templateName !== '' ? templateName : "template.pdf"}
                                            options={{
                                                orientation: 'potrait',
                                                unit: 'px',
                                                format: [printDimension.w, printDimension.h]
                                            }}
                                            scale={1}>
                                            {({ toPdf }) => (
                                                <button onClick={() => {
                                                    if (pdfContainer.current) {
                                                        setPrintDimension({
                                                            h: 0.545 * pdfContainer.current.offsetHeight,
                                                            w: 0.7 * pdfContainer.current.offsetWidth
                                                        });
                                                    }
                                                    setToastStateNoHide({
                                                        status: true,
                                                        bg: 'warning',
                                                        msg: 'Generating PDF'
                                                    });

                                                    setPreviewPrintState(true);
                                                    setTimeout(() => {
                                                        if (pdfContainer.current) {
                                                            setPrintDimension({
                                                                h: pdfContainer.current.offsetHeight,
                                                                w: pdfContainer.current.offsetWidth
                                                            });
                                                        }

                                                        toPdf();

                                                        setTimeout(() => {
                                                            setToastStateNoHide({
                                                                status: false,
                                                                bg: '',
                                                                msg: ''
                                                            });

                                                            setToastState({
                                                                status: true,
                                                                bg: 'success',
                                                                msg: 'Generate PDF Success'
                                                            });
                                                        }, 5000);
                                                    }, 15000);

                                                }} className="btn btn-sm btn-outline-danger me-2"><i className="fa fa-download"></i> PDF</button>
                                            )}
                                        </Pdf>
                                        <button hidden={idTemplate == null || idTemplate === undefined} onClick={() => setModalDeleteShow(true)} className="btn btn-sm btn-outline-secondary me-2"><i className="fa fa-trash"></i> Delete</button>
                                        <button onClick={() => { setPreviewState(false); setPrintState(false); }} className="btn btn-sm btn-outline-primary"><i className="fa fa-pencil"></i> Edit</button>
                                    </div>
                                </div>
                            </div>

                            <SectionOne />
                            <SectionTwo />
                            <SectionThree />
                            <SectionFour />
                        </div>
                    </div>
                </div>
                <div className="col-md-3" hidden={previewState}>
                    <div className="card card-container">
                        <div className="card-body">
                            <h6 className="header-section mb-3">Section Properties <Badge hidden={!unsaved} pill bg="danger">unsaved</Badge></h6>
                            <Accordion>
                                <FormSection id={0} setSectionValue={setFirstSectionValue} setWarning={setToastState} data={firstSection} table={table} unsaved={setUnsaved} refreshTable={getTableData} dataSource={globalTable} filterList={filterList} globalFilter={globalFilter}/>
                                <FormSection id={1} setSectionValue={setSecondSectionValue} setWarning={setToastState} data={secondSection} table={table} unsaved={setUnsaved} refreshTable={getTableData} dataSource={globalTable} filterList={filterList} globalFilter={globalFilter}/>
                                <FormSection id={2} setSectionValue={setThirdSectionValue} setWarning={setToastState} data={thirdSection} table={table} unsaved={setUnsaved} refreshTable={getTableData} dataSource={globalTable} filterList={filterList} globalFilter={globalFilter}/>
                                <FormSection id={3} setSectionValue={setFourthSectionValue} setWarning={setToastState} data={fourthSection} table={table} unsaved={setUnsaved} refreshTable={getTableData} dataSource={globalTable} filterList={filterList} globalFilter={globalFilter}/>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer className="p-3" position="top-end">
                <Toast bg={toastState.bg} onClose={() => setToastState({ status: false, msg: '' })} show={toastState.status} delay={3000} autohide>
                    <Toast.Body className="text-white">
                        {toastState.msg}
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            <ToastContainer className="p-3" position="top-end">
                <Toast bg={toastStateNoHide.bg} onClose={() => setToastStateNoHide({ status: false, msg: '' })} show={toastStateNoHide.status}>
                    <Toast.Body className="text-white">
                        {toastStateNoHide.msg}
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            <Modal
                show={modalDeleteShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to permanently remove this template?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteData()}>Delete</Button>
                    <Button onClick={() => setModalDeleteShow(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal save={() => saveData()} confirmText="Save & Exit" />

            <Modal
                show={modalDataSourceShow}
                size="md"
                aria-labelledby="modal-data-source"
                id="modal-data-source"
                centered
                onHide={handleCloseDataSource}
            >
                <Modal.Header>
                    <Modal.Title id="modal-data-source">
                        Data Source
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-3">
                        <label htmlFor="" className="form-label">Data Source</label>
                        <Select value={{ label: globalTable.label, value: globalTable.value }} defaultValue={{ label: globalTable.label, value: globalTable.value }} options={table} className="w-100" isMulti={false} onChange={(e) => {
                            setGlobalTable(e);
                            handleCloseDataSource();
                        }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={handleCloseDataSource}>Save</Button> */}
                    <Button onClick={handleCloseDataSource}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalFilterGlobalShow}
                size="md"
                aria-labelledby="modal-filter-global"
                id="modal-filter-global"
                centered
                onHide={handleCloseFilterGlobal}
            >
                <Modal.Header>
                    <Modal.Title id="modal-filter-global">
                        Filter Global
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div id="example-collapse-text">
                            <label htmlFor="" className="form-label">Filter Column</label>
                            <Select value={globalFilter.column} options={columnsValue} className="w-100" isMulti={false} onChange={(e) => {
                                var obj = {
                                    column: e,
                                    filter: globalFilter.filter,
                                    condition: globalFilter.condition
                                };
                                setGlobalFilter(obj);
                            }} />
                            <Select options={optFilter} defaultValue={globalFilter.filter} value={globalFilter.filter} onChange={(e) => {
                                var obj = {
                                    column: globalFilter.column,
                                    filter: e,
                                    condition: globalFilter.condition
                                };
                                setGlobalFilter(obj);
                            }}></Select>
                            <input type="text" onChange={(e) => {
                                var obj = {
                                    column: globalFilter.column,
                                    filter: globalFilter.filter,
                                    condition: e.target.value
                                };
                                setGlobalFilter(obj);
                            }} className="form-control" placeholder="Condition" value={globalFilter.condition} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFilterGlobal}>Save</Button>
                    <Button onClick={handleCloseFilterGlobal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Templates;
import React, { lazy, Suspense } from 'react';
import ChartBar from '../../ChartBar';
import ChartLine from '../../ChartLine';
import Loader from '../../Loader';

const Tables = lazy(() => import('../../Tables'));
const Widgets = lazy(() => import('../../Widgets'));
const Chart = lazy(() => import('../../Chart'));
const StackedChart = lazy(() => import('../../StackedChart'));
const PieChart = lazy(() => import('../../ChartPie'));
const GaugeChart = lazy(() => import('../../Gauge'));

export default function Section(props) {
    const capitalize = (str) => {
        var string = str.split(' ');
        for (var i in string) {
            string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
        }

        return string.join(' ');
    }

    const Com = () => {
        if (props.data !== null) {
            switch (props.data.type) {
                case 'table':
                    return <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>
                                {props.data.name}
                                <span className="badge ms-3" style={badgeStyle()}>{capitalize(props.data.type)}</span>
                            </h5>
                            <div hidden={props.printState} className="no-print">
                                <button hidden={true} type="button" className="btn btn-sm btn-outline-primary me-2">
                                    <i className="fa fa-pen me-2"></i>
                                    Edit
                                </button>

                                <button onClick={() => props.deletePrompt(props.id)} type="button" className="btn btn-sm btn-outline-secondary me-2">
                                    <i className="fa fa-trash me-2"></i>
                                    Delete
                                </button>
                            </div>
                        </div>
                        <Tables data={props.data} globalFilter={props.globalFilter}/>
                    </>

                case 'chart':
                    return <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>
                                {props.data.name}
                                <span className="badge ms-3" style={badgeStyle()}>{capitalize(props.data.type)}</span>
                            </h5>
                            <div hidden={props.printState} className="no-print">
                                <button hidden={true} type="button" className="btn btn-sm btn-outline-primary me-2">
                                    <i className="fa fa-pen me-2"></i>
                                    Edit
                                </button>

                                <button onClick={() => props.deletePrompt(props.id)} type="button" className="btn btn-sm btn-outline-secondary me-2">
                                    <i className="fa fa-trash me-2"></i>
                                    Delete
                                </button>
                            </div>
                        </div>
                        {
                            props.data.chartType=='line'?<ChartLine data={props.data} globalFilter={props.globalFilter}/>:''
                        }
                        {
                            props.data.chartType=='bar'?<ChartBar data={props.data} globalFilter={props.globalFilter}/>:''
                        }
                        {
                            props.data.chartType=='area'?<Chart data={props.data} globalFilter={props.globalFilter}/>:''
                        }
                        
                    </>

                case '4 widget':
                    return <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>
                                {props.data.name}
                                <span className="badge ms-3" style={badgeStyle()}>{capitalize(props.data.type)}</span>
                            </h5>
                            <div hidden={props.printState} className="no-print">
                                <button hidden={true} type="button" className="btn btn-sm btn-outline-primary me-2">
                                    <i className="fa fa-pen me-2"></i>
                                    Edit
                                </button>

                                <button onClick={() => props.deletePrompt(props.id)} type="button" className="btn btn-sm btn-outline-secondary me-2">
                                    <i className="fa fa-trash me-2"></i>
                                    Delete
                                </button>
                            </div>
                        </div>
                        <Widgets data={props.data.value} globalFilter={props.globalFilter}/>
                    </>

                case 'chart2':
                    return <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5>
                                    {props.data.name}
                                    <span className="badge ms-3" style={badgeStyle()}>2 Charts</span>
                                </h5>
                                <div hidden={props.printState} className="no-print">
                                    <button hidden={true} type="button" className="btn btn-sm btn-outline-primary me-2">
                                        <i className="fa fa-pen me-2"></i>
                                        Edit
                                    </button>

                                    <button type="button" onClick={() => props.deletePrompt(props.id)} className="btn btn-sm btn-outline-secondary me-2">
                                        <i className="fa fa-trash me-2"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                        {typeof props.data.form !=='undefined' || props.data.form!=null? props.data.form.map((item, index) => {
                            return (
                                <div className="col-xxl-6 col-xl-6 col-12" key={index}>
                                    {
                                        (item !== null) &&
                                        <>
                                            {(item.form.chart === 'stacked') && <StackedChart data={item.form} globalFilter={props.globalFilter} />}
                                            {(item.form.chart === 'line') && <Chart data={item.form} globalFilter={props.globalFilter} />}
                                            {(item.form.chart === 'pie') && <PieChart data={item.form} globalFilter={props.globalFilter} />}
                                            {(item.form.chart === 'gauge') && <GaugeChart data={item.form} globalFilter={props.globalFilter} />}
                                        </>
                                    }
                                </div>
                            )
                        }):''}
                    </div>

                default:
                    return <></>
            }
        } return <></>;
    };

    const badgeStyle = () => {
        if (props.data !== null) {

            switch (props.data.type) {
                case 'table':
                    return {
                        color: '#F43C3C',
                        fontWeight: 500,
                        backgroundColor: 'rgba(244, 60, 60, 0.08)'
                    }

                case 'chart':
                    return {
                        color: '#07A287',
                        fontWeight: 500,
                        backgroundColor: 'rgba(7, 162, 135, 0.08)'
                    }

                case '4 widget':
                    return {
                        color: '#FCA549',
                        fontWeight: 500,
                        backgroundColor: 'rgba(252, 165, 73, 0.08)'
                    }

                default:
                    return {
                        color: '#5552D9',
                        fontWeight: 500,
                        backgroundColor: '#5552D914'
                    }
            }
        } return {};
    };

    if (props.data !== null) {
        return (
            <div className="section-container-filled mb-4">
                <Suspense fallback={<Loader />}>
                    <Com />
                </Suspense>
            </div>)
    }

    return (
        <>
            <div className="section-container" hidden={props.preview || props.printState}>
                <button
                    // onClick={() => { props.toggle(props.id) }}
                    className="bg-transparent text-secondary border-0 p-0 w-100 h-100">
                    {/* <i className="fa fa-plus me-2"></i>  */}
                    {"Section " + props.id}
                </button>
            </div>
        </>
    )
}

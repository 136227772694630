import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { GetSessionStatus } from '../auth/Auth'

const AuthMiddleware = () => {
    const location = useLocation();
    const [status, setStatus] = useState(true)

    useEffect(() => {
        GetSessionStatus()
            .then(data => {
                setStatus(data.status)
            })
    }, [])

    if (!status) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />
}

export default AuthMiddleware;
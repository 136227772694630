import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import Auth from '../../auth/Auth';
import { Button, Collapse } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;
export default function Chart(props) {
    const [formTable, setFormTable] = useState(null);
    const [chartType, setChartType] = useState('');
    const [tableValue, setTableValue] = useState(props.table);
    const [tableSelected, setTableSelected] = useState({ value: props.table, label: props.tableLabel });

    const [xValue, setXValue] = useState(null);
    const [yValue, setYValue] = useState(null);
    const [xValueList, setXValueList] = useState([]);
    const [yValueList, setYValueList] = useState([]);

    const [dataType, setDataType] = useState(null);
    const [action, setAction] = useState(null);

    const [actionList, setActionList] = useState([]);

    const [formFilter, setFormFilter] = useState({ label: '', value: '' });
    const [formCondition, setFormCondition] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [filterColumn, setFilterColumn] = useState('');
    const [columnsValue, setColumnsValue] = useState([]);

    const [showTable, setShowTable] = useState(true);

    const fullAction = props.action.map((item, index) => {
        return {
            value: item.key,
            label: item.name,
            numeric: item.is_numeric
        }
    });

    const fullFilter = props.optionFilter.map((item, index) => {
        return {
            value: item.key,
            label: item.name,
            data_type: item.data_type
        }
    });


    const listColumns = async (value) => {
        await fetch(API_URL + 'report/listColumns', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ table: value })
        }).then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    const numeric = [];

                    data.result.map((item, index) => {
                        numeric.push({
                            value: item.name,
                            label: item.name,
                            type: item.data_type
                        });

                        return true;
                    });

                    setXValueList(numeric);
                    setYValueList(numeric);

                    setColumnsValue(numeric);
                }
            }).catch(console.error);
    }

    useEffect(() => {
        let hasDataSource = false;
        if (Object.entries(props.dataSource).toString() === Object.entries({ label: '', value: '' }).toString()) {
            setShowTable(true);
            hasDataSource = false;
        } else {
            setShowTable(false);
            hasDataSource = true;
        }
        if (props.data !== undefined && props.data !== null) {
            if (!hasDataSource) {
                setFormTable(props.data.table);
                if (props.data.table !== undefined && props.data.table !== null && props.data.table !== '') {
                    listColumns(props.data.table);
                }
                tableValue.forEach((item) => {
                    if (props.data.table === item.value) {
                        setTableSelected(item);
                    }
                })
            } else {
                setFormTable(props.dataSource.value);
                if (props.dataSource.value) {
                    listColumns(props.dataSource.value);
                }
                setTableSelected(props.dataSource);
            }
            setChartType(props.data.chartType === undefined ? 'area' : props.data.chartType);

            setXValue(props.data.x);
            setYValue(props.data.y);
            setAction(props.data.action);

            if (props.data.filter != null && typeof props.data.filter != 'undefined') {
                let open = true;
                let obj = {
                    column: '',
                    filter: { label: '', value: '' },
                    condition: ''
                }
                if (Object.entries(props.data.filter).toString() === Object.entries(obj).toString()) {
                    open = false;
                }
                setFilterColumn(props.data.filter.column);
                setFormFilter(props.data.filter.filter);
                setFormCondition(props.data.filter.condition);
                setOpenFilter(open);
            }
        }

        if (!props.table || !props.table.length) {
            props.refreshTable();
        } else {
            setTableValue(props.table);
        }
    }, [props]);

    useEffect(() => {
        const num = ['integer', 'float'];
        if (num.includes(dataType)) {
            const tmp = [];
            fullAction.map(item => {
                tmp.push({
                    value: item.value,
                    label: item.label
                });

                return true;
            });

            setActionList(tmp);
        } else {
            const tmp = [];
            fullAction.map(item => {
                if (!item.numeric) {
                    tmp.push({
                        value: item.value,
                        label: item.label
                    });

                    return true;
                }

                return false;
            });

            setActionList(tmp);
        }
    }, [dataType]);

    useEffect(() => {
        let type = '';
        if (filterColumn.type === 'text') {
            type = 'string';
        } else if (filterColumn.type === 'integer') {
            type = 'number';
        }
        let tmp = [];
        fullFilter.forEach(item => {
            if (item.data_type.includes(type)) {
                let obj = {
                    value: item.value,
                    label: item.label
                }
                tmp.push(obj);
            }
        });
        setFilterList(tmp);
    }, [filterColumn]);

    return (
        <>
            <div className="form-group mb-3">
                <label htmlFor="chartType" className="form-label">Chart Type</label>
                <select id="chartType" value={chartType} onChange={(e) => {
                    setChartType(e.target.value);
                    props.setValue({
                        chartType: e.target.value,
                        table: formTable,
                        tableLabel: tableSelected.label,
                        x: xValue,
                        y: yValue,
                        action: action,
                        filter: {
                            column: filterColumn,
                            filter: formFilter,
                            condition: formCondition
                        }
                    })
                }} className="form-select">
                    <option value="">Choose Chart Type</option>
                    <option value="area">Area</option>
                    <option value="line">Line</option>
                    <option value="bar">Bar</option>
                </select>
            </div>
            {showTable?<div className="form-group mb-3">
                <label htmlFor="" className="form-label">Data Source</label>
                <Select options={tableValue} className="w-100" defaultValue={{ label: tableSelected.label, value: formTable }} value={{ label: tableSelected.label, value: formTable }} onChange={(e) => {
                    setFormTable(e.value);
                    listColumns(e.value);
                    setTableSelected(e);
                    props.setValue({
                        chartType: chartType,
                        table: e.value,
                        tableLabel: e.label,
                        x: xValue,
                        y: yValue,
                        action: action,
                        filter: {
                            column: filterColumn,
                            filter: formFilter,
                            condition: formCondition
                        }
                    })
                }} />
            </div>:''}

            <div className="form-group mb-3">
                <label htmlFor="" className="form-label">X Axis</label>
                <Select options={xValueList} className="w-100" defaultValue={{ label: xValue, value: xValue }} value={{ label: xValue, value: xValue }} onChange={(e) => {
                    setXValue(e.value);
                    props.setValue({
                        chartType: chartType,
                        table: formTable,
                        tableLabel: tableSelected.label,
                        x: e.value,
                        y: yValue,
                        action: action,
                        filter: {
                            column: filterColumn,
                            filter: formFilter,
                            condition: formCondition
                        }
                    })
                }} />
            </div>

            <div className="form-group mb-3">
                <label htmlFor="" className="form-label">Y Axis</label>
                <Select options={yValueList} className="w-100" defaultValue={yValue} value={yValue} isMulti={true} onChange={(e) => {
                    setDataType('integer');
                    let arr = [];
                    const num = ['float', 'integer']
                    e.map((obj) => {
                        arr.push({
                            value: obj.value,
                            label: obj.value,
                            type: obj.type
                        });

                        if (!num.includes(obj.type)) {
                            setDataType(obj.type);
                        }

                        return true;
                    });

                    setYValue(arr);
                    props.setValue({
                        chartType: chartType,
                        table: formTable,
                        tableLabel: tableSelected.label,
                        x: xValue,
                        y: arr,
                        action: action,
                        filter: {
                            column: filterColumn,
                            filter: formFilter,
                            condition: formCondition
                        }
                    })
                }} />
            </div>

            <div className="form-group mb-3">
                <label htmlFor="" className="form-label">Function</label>
                <Select defaultValue={{ label: action, value: action }} value={{ label: action, value: action }} options={actionList} className="w-100" onChange={(e) => {
                    setAction(e.value);
                    props.setValue({
                        chartType: chartType,
                        table: formTable,
                        tableLabel: tableSelected.label,
                        x: xValue,
                        y: yValue,
                        action: e.value,
                        filter: {
                            column: filterColumn,
                            filter: formFilter,
                            condition: formCondition
                        }
                    })
                }} />
            </div>

            <div
                onClick={() => {
                    setOpenFilter(!openFilter)
                }}
                aria-controls="example-collapse-text"
                aria-expanded={openFilter}
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: 'pointer' }}>
                <div>
                    <Button
                        onClick={() => {
                            setOpenFilter(!openFilter)

                        }}
                        className="bg-transparent p-0 text-secondary border-0 me-2"
                        aria-controls="example-collapse-text"
                        aria-expanded={openFilter}>
                        {!openFilter && <i className="fa fa-chevron-down"></i>}
                        {openFilter && <i className="fa fa-chevron-up"></i>}
                    </Button>
                    <label htmlFor="" className="form-label">Filter</label>
                </div>
                <div className="form-check form-switch">
                    <input
                        onChange={(e) => {
                            if (e.target.checked) {
                                setOpenFilter(true);
                            } else {
                                var obj = {
                                    chartType: chartType,
                                    table: formTable,
                                    tableLabel: tableSelected.label,
                                    x: xValue,
                                    y: yValue,
                                    action: action,
                                    filter: {
                                        column: '',
                                        filter: { label: '', value: '' },
                                        condition: '-'
                                    }
                                };

                                props.setValue(obj);

                            }
                        }}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        aria-checked={openFilter}
                        id="flexSwitchCheckDefault"
                        checked={openFilter} />
                </div>
            </div>
            <div className="mb-3">
                <Collapse in={openFilter}>
                    <div id="example-collapse-text">
                        <label htmlFor="" className="form-label">Filter Column</label>
                        <Select value={filterColumn} options={columnsValue} className="w-100" isMulti={false} onChange={(e) => {
                            setFilterColumn(e);
                            console.log('set filter column', e);
                            var obj = {
                                chartType: chartType,
                                table: formTable,
                                tableLabel: tableSelected.label,
                                x: xValue,
                                y: yValue,
                                action: action,
                                filter: {
                                    column: e,
                                    filter: formFilter,
                                    condition: formCondition
                                }
                            };

                            props.setValue(obj);
                        }} />
                        <Select options={filterList} defaultValue={{ label: formFilter.label, value: formFilter.value }} value={{ label: formFilter.label, value: formFilter.value }} onChange={(e) => {
                            setFormFilter(e);
                            var obj = {
                                chartType: chartType,
                                table: formTable,
                                tableLabel: tableSelected.label,
                                x: xValue,
                                y: yValue,
                                action: action,
                                filter: {
                                    column: filterColumn,
                                    filter: e,
                                    condition: formCondition
                                }
                            };

                            props.setValue(obj);
                        }}></Select>
                        <input type="text" onChange={(e) => {
                            setFormCondition(e.target.value);
                            var obj = {
                                chartType: chartType,
                                table: formTable,
                                tableLabel: tableSelected.label,
                                x: xValue,
                                y: yValue,
                                action: action,
                                filter: {
                                    column: filterColumn,
                                    filter: formFilter,
                                    condition: e.target.value
                                }
                            };
                            props.setValue(obj);
                        }} className="form-control" placeholder="Condition" value={formCondition} />
                    </div>
                </Collapse>
            </div>
        </>
    )
}

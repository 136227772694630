export async function GetSessionStatus() {
    const storedSession = await new Promise((resolve) => {
        let sessionData = localStorage.getItem('session')
        resolve(sessionData)
    })

    const data = {
        status: false,
        user: {
            userId: null,
            nama:null
        },
        token: null
    }

    if (storedSession !== null) {
        const Crypt = require('crypto-js');
        let decrypted = Crypt.enc.Base64.parse(storedSession);
        decrypted = decrypted.toString(Crypt.enc.Utf8);
        const parsedStoredSession = JSON.parse(decrypted)
        data.status = parsedStoredSession.status
        data.user = parsedStoredSession.user
        data.nama = parsedStoredSession.nama
        data.token = parsedStoredSession.token
    }

    return data
}

class Auth {
    constructor() {
        if (localStorage.getItem('session') !== null) {
            var session = localStorage.getItem('session');
            session = JSON.parse(this.decode64(session));
            this.status = session.status;
            this.user = session.user;
            this.nama = session.nama;
            this.token = session.token;
        } else {
            this.status = false;
            this.user = {
                userId: null,
                nama:null,
            };
            this.token = null;
        }
    }

    attempt(user, password, remember) {
        let msg = 'You Are Being Redirected';
        return fetch('https://apidv.colibri.id/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user, password: password })
        }).then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.status = data.status;
                    this.token = data.token;
                    this.user = {
                        name: data.name,
                        email: data.email
                    };

                    if (remember) {
                        var encrypted = this.encode64(JSON.stringify({
                            status: data.status,
                            token: data.token,
                            user: {
                                name: data.name,
                                email: data.email
                            }
                        }));

                        localStorage.setItem('session', encrypted);
                    }
                } else {
                    msg = data.msg;
                }

                return {
                    status: this.status,
                    msg: msg
                };
            });
    }

    logout() {
        this.status = false;
        this.user = null;
        this.token = null;

        if (localStorage.getItem('session') !== null) {
            localStorage.removeItem('session');
        }

        return true;
    }

    user() {
        return this.user;
    }

    userId(){
        return this.user.userId;
    }

    token() {
        return this.token;
    }

    isAuthenticated() {
        return this.status;
    }

    encode64(params) {
        const Crypt = require('crypto-js');
        let encrypted = Crypt.enc.Utf8.parse(params);
        encrypted = Crypt.enc.Base64.stringify(encrypted);
        return encrypted;
    }

    decode64(params) {
        const Crypt = require('crypto-js');
        let decrypted = Crypt.enc.Base64.parse(params);
        decrypted = decrypted.toString(Crypt.enc.Utf8);
        return decrypted;
    }
}

export default new Auth();
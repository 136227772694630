import React, { useEffect } from "react";
import useConfirm from "./useConfirm";
import { Modal, Button } from "react-bootstrap";

const ConfirmModal = ({save,confirmText='Ok'}) => {
    const {
        prompt = "",
        isOpen = false,
        proceed,
        cancel
    } = useConfirm();

    useEffect(() => {
        const handleKeydown = (e) => {
            if (proceed && isOpen && e.key === "Enter") {
                proceed();
            }
        };

        window.addEventListener("keydown", handleKeydown);
        return () => window.removeEventListener("keydown", handleKeydown);
    }, [proceed, isOpen]);

    return (
        <Modal show={isOpen}>
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Body>{prompt}</Modal.Body>
            <Modal.Footer>
                <Button
                    color="primary"
                    onClick={() => { save().then(proceed) }}
                    className="ml-4"
                >{confirmText}</Button>
                <Button
                    color="secondary"
                    onClick={cancel}
                >Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ConfirmModal;
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Loader from './Loader';
import Auth from '../auth/Auth';

const DataTable = lazy(() => import('react-data-table-component'));
const API_URL = process.env.REACT_APP_API_URL;

export default function Tables(props) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data.columns) {
            setColumns(
                props.data.columns.map((item, index) => {
                    return {
                        name: item.value.charAt(0).toUpperCase() + item.value.slice(1).replace('_', ' '),
                        selector: row => row[item.value],
                        sortable: true,
                        orderable: true,
                        reorder: true
                    };
                })
            );
        }
        

        const dataTable = async () => {
            const body = {
                table: props.data.table,
                columns: props.data.columns,
                component: 'table',
                filter: props.data.filter,
                global_filter:props.globalFilter
            };

            await fetch(API_URL+'report/data-query', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'token': Auth.token
                },
                body: JSON.stringify({ data: body })
            }).then(response => response.json())
                .then(data => {
                    if (data.response === 1) {
                        setData(data.result);
                    }
                });
        }
        if (props.data.table !== undefined && props.data.table!==null) {
            dataTable().catch(console.error);
        }
        if (props.data.data!==undefined && props.data.data!==null) {
            setData(props.data.data);
        }
        
    }, [props.data]);


    return (
        <Suspense fallback={<Loader />}>
            <DataTable
                onColumnOrderChange={cols => setColumns(cols)}
                highlightOnHover
                pagination
                columns={columns}
                data={data}
            />
        </Suspense>
    );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Auth from '../auth/Auth';

import Loader from '../components/Loader';
import '../styles/list.css';

const API_URL = process.env.REACT_APP_API_URL;
export default function List() {
    const navigate = useNavigate();

    const [templateList, setTemplateList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [template, setTemplate] = useState([]);

    const handleNavigate = (e, path) => {
        e.preventDefault();
        navigate(path, { replace: true });
    }

    const fetchData = () => {
        fetch(API_URL+'template/all', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            }
        }).then(response => response.json())
            .then(data => {
                if (data.response === 1) {
                    const tmp = data.result.map((item, index) => {
                        return {
                            id: item.dvTemplateId,
                            title: item.dvTemplateName
                        }
                    });

                    setTemplate(tmp);
                    setTemplateList(tmp);
                }
            });

    }

    const deleteTemplate = async (id) => {
        await fetch(API_URL+'template/delete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({
                dvTemplateId: id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    fetchData();
                }
            });
    }

    useEffect(() => {
        const filteredData = template.filter(key => {
            if (searchValue === '') {
                return key;
            }

            if (key.title.indexOf(searchValue) !== -1) {
                return key;
            }

            return false;
        });

        setTemplateList(filteredData);
    }, [searchValue]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container-fluid py-3" id="List">
                <div className="card card-container">
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-4">
                            <h4
                                onClick={(e) => handleNavigate(e, '/dashboard')}
                                className="header-text d-flex align-items-center"
                                style={{ cursor: "pointer" }}>
                                <i className="fa fa-chevron-left me-2"></i>
                                Template Gallery
                            </h4>
                            <div className="mw-25">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} aria-label="Search"
                                        aria-describedby="basic-addon2" />
                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {Object.keys(template).length === 0 && <Loader />}
                            {templateList &&
                                templateList.map((item) => {
                                    return <div className="col-xl-20 col-lg-3 col-md-6 col-12" key={item.id}>
                                        <div className="card mb-3">
                                            <div style={{ cursor: "pointer" }} className="card-body d-flex justify-content-center align-items-center h-100" onClick={(e) => handleNavigate(e, "/template/" + item.id + '/preview')}>
                                                <img src="/img/tmp.png" className="img-fluid" alt="" />
                                            </div>
                                            <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
                                                <p className="mb-0 title-text">{item.title}</p>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="bg-transparent border-0" id="dropdown-basic">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={(e) => handleNavigate(e, "/template/" + item.id + '/preview')}>
                                                            <i className="fa fa-pencil"></i> Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => deleteTemplate(item.id)}><i className="fa fa-trash"></i> Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

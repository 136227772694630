import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from '../../forms/Forms';

const FormSection = (props) => {
    const dataCheck = props.data !== undefined && props.data !== null;
    const [sectionName, setSectionName] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [sectionForm, setSectionForm] = useState(null);
    const [isChangeSectionName, setIsChangeSectionName] = useState(false);

    const setSectionFormValue = (value) => {
        setSectionForm(value);
        updateObjVal(null,value);
    }

    const formTranslate = {
        'table': 'Table',
        'chart': 'Chart',
        '4 widget': 'Widgets',
        'chart2': 'Chart 2'
    }

    const checkForm = (form) => {
        let status = true;

        if (form === undefined || form === null) {
            props.setWarning({
                status: true,
                msg: formTranslate[sectionType] + ' Form is Required',
                bg: 'danger'
            });

            status = false;
        } else if (Array.isArray(form)) {
            if (form.length > 0) {
                form.map((item, index) => {
                    if (!checkForm(item)) {
                        let msg = 'Form is Required'
                        props.setWarning({
                            status: true,
                            msg: msg,
                            bg: 'danger'
                        });

                        status = false;
                    }

                    return true;
                });
            } else {
                status = false;
            }
        } else if (typeof form === 'object') {
            if (Object.keys(form).length > 0) {
                Object.keys(form).map((item) => {
                    if (!checkForm(form[item])) {
                        let msg = item.charAt(0).toUpperCase() + item.slice(1) + ' is Required'
                        props.setWarning({
                            status: true,
                            msg: msg,
                            bg: 'danger'
                        });

                        status = false;
                    }

                    return true;
                });
            } else {
                status = false;
            }
        }

        return status;
    }

    const handleSubmit = () => {
        if ((sectionType !== '' && sectionName !== '') || sectionForm !== null) {
            if (sectionType === 'None' || sectionType === '') {
                props.setSectionValue(null);
            } else {
                let formStatus = checkForm(sectionForm);
                if (formStatus) {
                    var tmp = {
                        section: props.id + 1,
                        name: sectionName,
                        type: sectionType
                    };

                    var obj = sectionForm;
                    var retObj = Object.assign({}, obj, tmp);
                    props.setSectionValue(retObj);
                }
            }
        }else{
            console.log(false)
        }
    }
    
    const updateObjVal = (tmp=null, obj=null)=>{
        if(tmp==null){
            tmp = {
                section: props.id + 1,
                name: sectionName,
                type: sectionType
            };
        }
        
        if (obj==null) {
            obj = sectionForm;
        }
        var retObj = Object.assign({}, obj, tmp);
        props.setSectionValue(retObj);
        props.unsaved(true);
    }

    useEffect(() => {
        if ((sectionName == null || sectionName==='') && !isChangeSectionName) {
            setSectionName("Section " + (props.id + 1));
        }
        if (dataCheck) {
            setSectionName(props.data.name);
            setSectionType(props.data.type);
            setSectionForm(props.data);
        }

    }, [props]);
    

    return (
        <Accordion.Item eventKey={props.id}>
            <div className="accordion-header">
                <Accordion.Button>
                    {sectionName !== '' ? sectionName : "Section " + (props.id + 1)}
                </Accordion.Button>
            </div>
            <Accordion.Body>
                <div className="form-group mb-3" hidden={!(sectionType !== 'None')}>
                    <label htmlFor="sOneName" className="form-label">Name</label>
                    <input type="text" onChange={(e) => { 
                        setSectionName(e.target.value);
                        setIsChangeSectionName(true);
                        updateObjVal({
                            section: props.id + 1,
                            name: e.target.value,
                            type: sectionType
                        },sectionForm);
                        }} className="form-control" placeholder="Dashboard" defaultValue={sectionName !== '' ? sectionName : "Section " + (props.id + 1)} />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="sOneType" className="form-label">Type</label>
                    <select id="sOneType" value={sectionType} onChange={(e) => { 
                            setSectionType(e.target.value); 
                            updateObjVal({
                                section: props.id + 1,
                                name: sectionName,
                                type: e.target.value
                            },sectionForm);
                        }} className="form-select">
                        <option value="">Choose Type</option>
                        <option value="table">Table</option>
                        <option value="chart">Chart</option>
                        <option value="chart2">2 Charts</option>
                        <option value="4 widget">4 Widgets</option>
                        <option value="None">None</option>
                    </select>
                </div>
                {(sectionName !== '' && sectionType !== '') &&
                    <Form id={props.id} type={sectionType} data={sectionForm} table={props.table} setValue={setSectionFormValue} refreshTable={props.refreshTable} setWarning={props.setWarning} dataSource={props.dataSource} filterList={props.filterList}  globalFilter={props.globalFilter}/>
                }

                <button type="button" onClick={handleSubmit} className="btn btn-sm btn-primary w-100"><i className="fa fa-check"></i> Apply Changes</button>
            </Accordion.Body>
        </Accordion.Item >
    );
}
export default FormSection;
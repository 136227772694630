import React, { useState } from 'react';
import { Link, NavLink, useLocation, Navigate } from 'react-router-dom'
import Auth from '../../../auth/Auth';

export default function Navbar(props) {
    const location = useLocation();

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleCollapse = (e) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    }

    const handleLogout = () => {
        if (Auth.logout()) {
            return <Navigate to="/" state={{ from: location }} />
        }
    }

    return <div>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header justify-content-end">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="row mb-5">
                    <div className="col-3">
                        <img src="/img/pic.png" alt="" className="h-100" />
                    </div>
                    <div className="col align-items-center">
                        <h3 className="mb-1">{Auth.user.name}</h3>
                        <small className="mb-0 text-muted">{Auth.user.email}</small>
                    </div>
                </div>
                <nav className="nav flex-column">
                    <li className="nav-item">
                        <NavLink to="/dashboard" className="nav-link btn d-flex justify-content-start align-items-center">
                            <i className="fa fa-home me-3"></i>
                            Dashboard
                        </NavLink>
                    </li>
                    <li><hr /></li>
                    <li className="nav-item">
                        <a href="/#" onClick={handleLogout} className="nav-link">Logout</a>
                    </li>
                </nav>
            </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
            <div className="container-fluid py-3">
                <Link to="/dashboard" className="navbar-brand fw-bolder d-flex align-items-center justify-content-between shadow-sm">
                    <img src="/img/logo.png" alt="" className="me-3" style={{ marginTop: -5 + 'px' }} height="30" />
                    <span className="d-lg-block d-none">ColibriView</span>
                </Link>
                <button
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#333'
                    }}
                    className="btn btn-primary d-lg-none d-block"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample">
                    <i className="fa fa-bars"></i>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to="/dashboard" className="nav-link btn d-flex justify-content-start align-items-center">
                                <i className="fa fa-home me-3"></i>
                                Dashboard
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                        <li className="nav-item">
                            <a href="/#" className="nav-link me-2">
                                <i className="fa-regular fa-bell fa-xl" style={{ color: "#909090" }}></i>
                                <span className="notif-highlight"></span>
                            </a>
                        </li>
                        <li className="nav-item account-control card">
                            <a className="nav-link" href="/#collapseExample" onClick={(e) => handleCollapse(e)}
                                aria-expanded={isCollapsed} style={{ zIndex: 1 }}>
                                <i className="fa-solid fa-circle-user fa-xl m-2" style={{ color: "#909090" }}></i>
                                <span className="d-md-flex d-none">{Auth.user.nama}</span>
                                <i className="fa fa-chevron-down me-2 d-md-flex d-none" style={{ color: "#27A857" }}></i>
                            </a>
                            <div className={isCollapsed ? 'collapse' : "collapsed"} id="collapseExample"
                                style={{ position: "absolute", zIndex: 0 + "!important", top: 35 + "px", border: "1px solid #909090", borderRadius: 15 + "px", width: 100 + "%" }}>
                                <div className="card card-body p-1"
                                    style={{ border: "none", borderRadius: 15 + "px", textAlign: "center" }}>
                                    <a href="/#" onClick={handleLogout} className="drop-link">Logout</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
}
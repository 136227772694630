import React, { Suspense } from 'react'
import Chart from '../../Chart';
import Loader from '../../Loader';

function DefaultChart() {
    const data = {
        y:[{label:'amt'}],
        x:'name',
        data: [
            {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
            },
        ]
    };
    return (
        <div className="section-container-filled mb-4">
            <Suspense fallback={<Loader />}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>
                        Area Chart
                        <span className="badge ms-3" style={{
                            color: '#07A287',
                            fontWeight: 500,
                            backgroundColor: 'rgba(7, 162, 135, 0.08)'
                        }}>Chart</span>
                    </h5>
                </div>
                <Chart data={data} />
            </Suspense>
        </div>
    )
}

export default DefaultChart
import React, { Suspense } from 'react'
import Loader from '../../Loader';
import Tables from '../../Tables';

function DefaultTable() {
    const data = {
        columns : [
            {
                value: 'title',
            },
            {
                value: 'year'
            },
        ],
        data : [
            {
                id: 1,
                title: 'Beetlejuice',
                year: '1988',
            },
            {
                id: 2,
                title: 'Ghostbusters',
                year: '1984',
            },
        ]
    };
    return (
        <div className="section-container-filled mb-4">
            <Suspense fallback={<Loader />}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>
                        Table
                        <span className="badge ms-3" style={{
                             color: '#F43C3C',
                             fontWeight: 500,
                             backgroundColor: 'rgba(244, 60, 60, 0.08)'
                        }}>Table</span>
                    </h5>
                </div>
                <Tables data={data} />
            </Suspense>
        </div>
    )
}

export default DefaultTable
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Default2Charts from '../components/templates/component/Default2Charts';
import DefaultChart from '../components/templates/component/DefaultChart';
import DefaultTable from '../components/templates/component/DefaultTable';
import DefaultWidget from '../components/templates/component/DefaultWidget';

function DefaultTemplates() {
    const navigate = useNavigate();
    const { idTemplate } = useParams();

    const templateName = 'Template '+idTemplate;

    const Com = () => {
        switch (idTemplate) {
            case "1":
                return <div>
                    <DefaultWidget/>
                    <DefaultChart/>
                    <Default2Charts data={[3,4]}/>
                    <DefaultTable/>
                </div>;
            case "2":
                return <div>
                    <DefaultChart/>
                    <Default2Charts data={[1,3]}/>
                    <DefaultWidget/>
                    <DefaultTable/>
                </div>;
            case "3":
                return <div>
                    <DefaultTable/>
                    <DefaultChart/>
                    <Default2Charts data={[1,5]}/>
                    <DefaultWidget/>
                </div>;
            case "4":
                return <div>
                    <Default2Charts data={[2,4]}/>
                    <DefaultWidget/>
                    <DefaultChart/>
                    <DefaultChart/>
                </div>;
        
            default:
                return <div></div>;
        }
    }
    
    const handleNavigate = (e, path) => {
        e.preventDefault();
        navigate(path, { replace: true });
    }

    return (
        <div className="container-fluid my-4" id="templates">
            <div className="row">
                <div className="col-12">
                    <div className="card card-container">
                        <div className="card-body">
                        <div className="row align-items-center">
                                <div className="col-lg-4 mb-4">
                                    <div className="input-group template-name">
                                        <input type="text" className="form-control" value={templateName}
                                            aria-describedby="basic-addon2" readOnly={true} />
                                    </div>
                                </div>
                                <div className="col-lg-8 mb-4 d-flex justify-content-lg-end justify-content-center">
                                    <button onClick={(e) => { handleNavigate(e, '/template/'+idTemplate) }} className="btn btn-sm btn-outline-primary"><i className="fa file-arrow-down"></i> Use this template</button>
                                    
                                </div>
                            </div>
                            <Com />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultTemplates
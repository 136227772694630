import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Auth, { GetSessionStatus } from '../auth/Auth';

import Loader from '../components/Loader';
import '../styles/dashboard.css';

const API_URL = process.env.REACT_APP_API_URL;
const Dashboard = () => {
    const navigate = useNavigate();

    const [templateList, setTemplateList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [template, setTemplate] = useState([]);
    // const [recentTemplate, setRecentTemplate] = useState([]);
    const [isRender, setIsRender] = useState(true);

    const handleNavigate = (e, path) => {
        e.preventDefault();
        navigate(path, { replace: true });
    }

    const templates=[
        {
            id:1,
            title:'Template 1',
            thumb:'/img/thumbnail-template-1.png'
        },
        {
            id:2,
            title:'Template 2',
            thumb:'/img/thumbnail-template-2.png'
        },
        {
            id:3,
            title:'Template 3',
            thumb:'/img/thumbnail-template-3.png'
        },
        {
            id:4,
            title:'Template 4',
            thumb:'/img/thumbnail-template-4.png'
        }
    ]

    const fetchData = () => {

        fetch(API_URL+'template/all', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({ userId: Auth.userId() })
        }).then(response => response.json())
            .then(data => {
                if (data.response === 1) {
                    const tmp = data.result.map((item, index) => {
                        return {
                            id: item.dvTemplateId,
                            title: item.dvTemplateName
                        }
                    });

                    setTemplate(tmp);
                    setTemplateList(tmp);

                    // const rec = data.result.slice(0, 3);
                    // setRecentTemplate(rec.map((item, index) => {
                    //     return {
                    //         id: item.dvTemplateId,
                    //         title: item.dvTemplateName
                    //     }
                    // }));
                    setIsRender(false);
                }
            });
    } 

    const deleteTemplate = async (id) => {
        await fetch(API_URL+'template/delete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'token': Auth.token
            },
            body: JSON.stringify({
                dvTemplateId: id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response === 1) {
                    fetchData();
                }
            });
    }

    useEffect(() => {
        const filteredData = template.filter(key => {
            if (searchValue === '') {
                return key;
            }

            if (key.title.indexOf(searchValue) !== -1) {
                return key;
            }

            return false;
        });

        setTemplateList(filteredData);
    }, [searchValue]);

    useEffect(() => {
        // fetchData();
        // console.log('Token',Auth.token);

        GetSessionStatus()
            .then(data => {
                fetch(API_URL+'template/all', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'token': data.token
                    },
                    body: JSON.stringify({ userId: data.user.userId })
                }).then(response => response.json())
                    .then(data => {
                        if (data.response === 1) {
                            const tmp = data.result.map((item, index) => {
                                return {
                                    id: item.dvTemplateId,
                                    title: item.dvTemplateName
                                }
                            });
        
                            setTemplate(tmp);
                            setTemplateList(tmp);
        
                            // const rec = data.result.slice(0, 3);
                            // setRecentTemplate(rec.map((item, index) => {
                            //     return {
                            //         id: item.dvTemplateId,
                            //         title: item.dvTemplateName
                            //     }
                            // }));
                            setIsRender(false);
                        }
                    });
            })
    }, []);

    return (
        <div className="container-fluid my-4" id="dashboard">
            <div className="header-container d-flex justify-content-between align-items-center">
                <h4 className="header-text mb-0">Create new view use template</h4>
                {/* <Link to="/list" className="header-link d-md-block d-none">
                    More Templates
                    <i className="fa fa-chevron-right fa-sm ms-2"></i>
                </Link> */}
            </div>
            <div className="card card-container card-container-header">
                <div className="card-body">
                    {isRender ? <Loader /> :
                        <div className="row">
                            <div className="col-xl-20 col-lg-3 col-md-6 col-12">
                                <div className="card mb-2" onClick={(e) => handleNavigate(e, '/template')} style={{ cursor: "pointer", height: "90%" }}>
                                    <div className="card-body d-flex align-items-center justify-content-center h-100">
                                        <i className="fa fa-plus" style={{ fontSize: 4 + "rem", color: "#A0AEC0" }}></i>
                                    </div>
                                </div>
                                <p className="mb-0 text-center">Blank</p>
                            </div>
                            {templates.map((item) => {
                                return <div className="col-xl-20 col-lg-3 col-md-6 col-6 d-md-block d-none" key={item.id}>
                                    <div className="card mb-2" >
                                        <div className="card-body d-flex justify-content-center align-items-center h-100" style={{ cursor: 'pointer' }} onClick={(e) => handleNavigate(e, "/template/default/" + item.id)}>
                                            <img src={item.thumb} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <p className="mb-0 text-center" style={{ fontSize: 1 + 'em' }}>{item.title}</p>
                                </div>
                            })
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="header-container">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-lg col-12">
                        <h4 className="header-text">Recent</h4>
                    </div>

                    <div className="col-lg-3 col-12 mb-0 mb-md-3">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} aria-label="Search"
                                aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-container">
                <div className="card-body">
                    <div className="row">
                        {isRender && <Loader />}
                        {templateList &&
                            templateList.map((item) => {
                                return <div className="col-xl-3 col-md-6 col-12" key={item.id}>
                                    <div className="card mb-3" >
                                        <div className="card-body d-flex justify-content-center align-items-center h-100" style={{ cursor: 'pointer' }} onClick={(e) => handleNavigate(e, "/template/" + item.id + '/preview')}>
                                            <img src="/img/tmp.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
                                            <p className="mb-0 title-text">{item.title}</p>
                                            <Dropdown>
                                                <Dropdown.Toggle className="bg-transparent border-0" id="dropdown-basic">
                                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={(e) => handleNavigate(e, "/template/" + item.id + '/edit')}>
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => deleteTemplate(item.id)}><i className="fa fa-trash"></i> Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Dashboard;